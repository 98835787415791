import React, {useCallback, useState} from "react";
import {
  TabbedForm,
  TextInput,
  FormTab,
  useRecordContext,
  useResourceContext,
  SelectArrayInput,
  required,
  FormDataConsumer
} from "react-admin";
import EditToolbar from "../EditToolbar";
import TemplateInput from "../../components/TemplateInput";
import AceEditorInput from "../../components/AceEditorInput";
import ChangelogFields from "../../components/ChangeLogFields";
import {Field} from "react-final-form";
import {makeStyles} from "@material-ui/core/styles";
import SampleButton from "../../components/SampleButton";
import PreviewArea from "../../components/PreviewArea";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((props) => ({
  medium_width_input: {
    width: "40%",
  },
  gridOptionsPreview: { marginBottom: "20px" },
  expansionPanel: { width: "100%", marginTop: "20px"},
  wrapperPreview: {
    width: "1200px",
    height: "10vh",
    overflow: "hidden",
    bottom: "0",
    marginTop: "15px",
    marginBottom: "15px",
    position: "fixed",
    zIndex: "1",
    border: "1px black solid",
    padding: "10px",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    "&:hover, &:focus": { height: "70vh" },
    transition: "height .5s",
    right:"150px",
  },
}));

const EditForm = ({ translate, ...props }) => {
  const classes = useStyles();

  return (
      <TabbedForm
          submitOnEnter={false}
          toolbar={<EditToolbar record={props.record} />}
          {...props}
      >
        <FormTab label="summary">
          <TextInput source="headline" validate={required()} />
          <SelectArrayInput
              source="tag"
              choices={[{ id: "offer", name: "Offer" },{ id: "layer", name: "Layer" }]}
          />

          <AceEditorInput
              labl="CSS template"
              name="css_template"
              source="css_template"
              row={"100px"}
          />

            <ExpansionPanel className={classes.expansionPanel} defaultExpanded={false}>
              <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>
                Tips
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                 Magazine Direct: The CTA button have to embedded with appropriate class name like this: <br/>
                &lt;div class="Kiosq-main-layer"&gt; <br/>
                &lt;div class="Kiosq-login-button custom"&gt; <br/>
                &lt;button&gt;CTA&lt;button&gt;
              </ExpansionPanelDetails>
            </ExpansionPanel>

          <TemplateInput />
          <FormDataConsumer>
            {({formData, ...rest}) =>
              <SampleButton source={'preview_data'} type={formData.tag} />
            }
          </FormDataConsumer>
          <AceEditorInput
              labl="Preview Data"
              name="preview_data"
              source="preview_data"
              row={"100px"}
          />
          <Field
              name={"preview_area"}
              height="100%"
              width="100%"
              component={PreviewArea}
              css={'css_template'}
              template={'template'}
              previewData={'preview_data'}
              parseDataFromJson={true}
          />
        </FormTab>
        <FormTab label="Changelogs" path="changelogs">
          <ChangeLogTabContent guid={props.record.guid} />
        </FormTab>
      </TabbedForm>
  )
}

const ChangeLogTabContent = (props) => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);

  if (record.guid && record.guid.length > 0) {
    return (
      <ChangelogFields guid={record.guid} record={record} resource={resource} />
    );
  } else {
    return null;
  }
};
export default EditForm;
