import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BooleanInput,DateTimeInput,FormDataConsumer}  from 'react-admin';

const useStyles = makeStyles({
    scheduled_maintenance_content: {
        display: 'flex',
    },
    scheduled_maintenance_input: {
        marginRight: '10%',
    }
});


const ScheduledMaintenanceForm = (props) =>{
    const classes = useStyles();
    return (
        <div className={classes.scheduled_maintenance_content}>
            <BooleanInput helperText="To activate scheduled maintenance"
                          label="Scheduled maintenance"
                          source="scheduled_maintenance"
                          className={classes.scheduled_maintenance_input} />
            <FormDataConsumer>
                {({ formData, ...rest }) =>{
                    if (formData.scheduled_maintenance === false) {
                      formData.scheduled_maintenance_start_date = null;
                    }
                    return (
                        <DateTimeInput  label="Start date"
                                        source="scheduled_maintenance_start_date"
                                        className={classes.scheduled_maintenance_input}
                                        disabled={!formData.scheduled_maintenance}
                        />
                    )}
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>{
                    if (formData.scheduled_maintenance === false) {
                      formData.scheduled_maintenance_end_date = null;
                    }
                    return (
                        <DateTimeInput  label="End date"
                                        source="scheduled_maintenance_end_date"
                                        disabled={!formData.scheduled_maintenance}

                        />
                    )}
                }
            </FormDataConsumer>

        </div>
    )
}

export default ScheduledMaintenanceForm;
