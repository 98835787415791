import React from 'react';
import {
    Filter,
    ReferenceInput,
    SelectInput,
    TextInput,
    DateInput,
    useListContext
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';

const UserFilter = (props) => {

    function createChoices(subject) {
        let tab = [];
        for (const [key, value] of Object.entries(window.sites_config)) {
            if (subject === 'sites') {
                if (key !== 'default') {
                    tab.push({id: window.sites_config[key].site, name: window.sites_config[key].name + ' ' + window.sites_config[key].local })
                }
            }
        }
        return tab;
    }

    const siteChoicesTab = createChoices('sites');

    let {filterValues} = useListContext();

    return(
        <Filter {...props}>
            <TextInput label="Search by email" source="q" alwaysOn />
            <SelectInput label="Grace period" source="paywall" choices={[
                { id: 'with-grace-period', name: 'With grace period' },
                { id: 'without-grace-period', name: 'Without grace period' }
            ]}
            />
            <SelectInput label="Trial period" source="trial" choices={[
                        { id: 'with-trial-period', name: 'With trial period' },
                        { id: 'without-trial-period', name: 'Without trial period' }
                    ]}
                />
            {
                (filterValues.paywall === 'with-grace-period' ||
                filterValues.paywall === 'without-grace-period' ||
                filterValues.trial === 'with-trial-period' ||
                filterValues.trial === 'without-trial-period')  &&
                <SelectInput label="Site" source="site" choices={siteChoicesTab} allowEmpty/>
            }
            <DateInput label="Subscription from" source="subscription-from"/>
            <DateInput label="Subscription to" source="subscription-to"/>
        </Filter>
    )
};

export default UserFilter;
