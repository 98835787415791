import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  useNotify,
  useRefresh,
  useRedirect,
  BooleanInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles} from '@material-ui/core/styles';
import TrafficExposureForm from '../../components/TrafficExposureForm'
import FormDataContent from '../../components/FormDataContent'
import ArrayInputContent from '../../components/ArrayInputContent'
import ScheduledMaintenanceForm from '../../components/ScheduledMaintenanceForm'
import LayersManager from '../../components/LayersManager'
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
    long_input: {
        width: '65%',
        marginRight: '10%'
    },
    group_input: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    text_editor_content: {
        width: '65%'
    },
    input_marge: {
        marginRight: '5%'
    },
    regwall_input: {
        marginLeft: '5%'
    },
    sub_title: {
      fontSize: "16px",
      marginBottom: '0px'
    },
    sub_title_underline: {
        width: '30px',
        height:'4px',
        backgroundColor: blue[400],
        marginBottom: '20px'
    },
});

const SiteConfigsCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`New Site configs "${data.name}" created`)
        redirect('/sites-configs');
        refresh();
    };
    const onFailure = (error) => {
        notify(`Could not create Site configs: ${error.message}`)
        redirect('/sites-configs');
        refresh();
    };

    const toolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

    const CustomToolbar = props => (
    <Toolbar {...props} classes={toolbarStyles()}>
        <SaveButton />
    </Toolbar>
);
const validateSiteConfigCreation = (values) => {
    const errors = {};
    const requiredFields = ['site', 'logo', 'logo_small', 'domain'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `The ${field.replace('_', ' ')} is required`;
        }
    });

    if (values.landing_products) {
        try {
          const json = JSON.parse(values.landing_products);
        } catch (e) {
          errors.landing_products = 'The format is not valid, please check the example provided';
        }
    }
    if (values.enableHandshakeRedirect === true) {
        if (!values.estore_handshake_endpoint) {
            errors.estore_handshake_endpoint = "The Estore handshake endpoint is required as  the 'enable hanshake redirect' button is checked"
        }
    }
    return errors
};
    return (
        <Create  onSuccess={onSuccess} onFailure={onFailure} {... props} >
            <TabbedForm
                submitOnEnter={false}
                redirect="list"
                toolbar={<CustomToolbar />}
                validate={validateSiteConfigCreation}
            >
                <FormTab label="site configs">
                    <FormDataContent dataContentTitle="General infos">
                        <div className={classes.group_input}>
                            <TextInput helperText="ex: cyclingnews_en-us" className={classes.input_marge} source="site" />
                            <TextInput helperText="ex: Cyclingnews" className={classes.input_marge} source="name" />
                            <SelectInput source="local"
                                choices={[
                                    { id: 'us', name: 'US' },
                                    { id: 'gb', name: 'GB' },
                                    { id: 'za', name: 'ZA' },
                                    { id: 'au', name: 'AU' },
                                    { id: 'ca', name: 'CA' },
                                    { id: 'nz', name: 'NZ' },
                                    { id: 'in', name: 'IN' },
                                    { id: 'my', name: 'MY' },
                                    { id: 'ae', name: 'AE' },
                                    { id: 'bh', name: 'BH' },
                                    { id: 'kw', name: 'KW' },
                                    { id: 'om', name: 'OM' },
                                    { id: 'qa', name: 'QA' },
                                    { id: 'sa', name: 'SA' },
                                    { id: 'dk', name: 'DK' },
                                    { id: 'fi', name: 'FI' },
                                    { id: 'no', name: 'NO' },
                                    { id: 'se', name: 'SE' },
                                    { id: 'it', name: 'IT' },
                                    { id: 'be', name: 'BE' },
                                    { id: 'de', name: 'DE' },
                                    { id: 'fr', name: 'FR' },
                                    { id: 'es', name: 'ES' },
                                    { id: 'eg', name: 'EG' }
                                ]}
                                label= "Locale"
                                className={classes.input_marge}
                            />
                        </div>
                        <TextInput helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/jtld3qrazv1613402249.svg" className={classes.long_input} source="logo" required={true} />
                        <TextInput helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/m7awu8serc1613169967.svg" className={classes.long_input} source="logo_small" required={true}/>
                        <div className={classes.group_input}>
                            <TextInput helperText= 'ex: https://cyclingnews.com' className={classes.long_input} label="Domain " source="domain" />
                        </div>
                        <TextInput helperText= 'To put a Kiosq button in discover brand landing page. ex: {"/decanter":"E001000", "/golf-monthly":"E003000"}' className={classes.long_input} label="Landing products " source="landing_products" />
                        <BooleanInput helperText="Currently available for MD and Arcade only. It will redirect the user to the provider page after login if no valid subscription is found"  label="Enable Estore handshake redirection after login" source="enableHandshakeRedirect" />
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>{
                                if (formData.enableHandshakeRedirect === true ) {
                                    return (
                                        <div >
                                            <TextInput helperText="ex: https://subscribe.homebuilding.co.uk/jwt/paywall.php" className={classes.long_input} label="Estore handshake endpoint" source="estore_handshake_endpoint" />
                                            <div>
                                                <TextInput helperText="Only used if the buy estore is Arcade" className={classes.input_marge} source="productID" label="productID" />
                                            </div>
                                        </div>

                                    )
                                }else {
                                    return null
                                }
                            }}
                        </FormDataConsumer>
                        <BooleanInput helperText="Does this site use trailing slash"  label="Trailing slash" source="trailingSlash" />
                    </FormDataContent>
                    <FormDataContent dataContentTitle="Paywall config">
                        <div className={classes.group_input}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>{
                                    if (typeof formData.wall_system === 'undefined' ) {
                                        formData.wall_system = 'classic'
                                    }
                                    return (
                                        <SelectInput source="wall_system"
                                                     choices={[
                                                        { id: 'classic', name: 'Classic' },
                                                        { id: 'metered', name: 'Metered' }
                                                     ]}
                                                     helperText="Default value: 'Classic'"
                                                     label="Wall mode"
                                        />
                                    )}
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (typeof formData.wall_type === 'undefined' ) {
                                        formData.wall_type = 'paywall'
                                    }
                                    let wallTypeChoices = [
                                        { id: 'paywall', name: 'Paywall' },
                                        { id: 'regwall', name: 'Regwall' },
                                        { id: 'regwallAuthentication', name: 'Regwall + Authentication' },
                                    ]
                                    //Regwall + Paywall only available in metered mode
                                    if (formData.wall_system === 'metered'){
                                        wallTypeChoices.push({ id: 'regwallAuthPaywall', name: 'Regwall + Paywall' })
                                    }
                                    return (
                                        <SelectInput source="wall_type"
                                            choices={wallTypeChoices}
                                            helperText="Default value: 'Paywall'"
                                            className={classes.regwall_input}
                                        />
                                    )}
                                }
                            </FormDataConsumer>
                        </div>
                        <div className={classes.group_input}>
                            <TextInput helperText="default: 96[px] (Number of pixel from the top of the article body to see before showing the content barrier on desktop)" className={classes.input_marge}  label="Limit of content Desktop" source="limitOfContent" />
                            <TextInput helperText="default: 96[px] (Number of pixel from the top of the article body to see before showing the content barrier on mobile)" className={classes.input_marge}  label="Limit of content Mobile" source="limitOfContentMobile" />
                        </div>
                        <div className={classes.group_input}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData.wall_system === 'metered'){
                                        return(
                                            <TextInput helperText="default: 3 (Number of articles that can be viewed before the paywall appears)" className={classes.input_marge}  label="Limit of free articles" source="limitOfFreeArticle"/>
                                        )
                                    }else {
                                        return null
                                    }
                                }}
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData.wall_system === 'metered'){
                                        return(
                                            <TextInput helperText="Put a number to specify from which number of article left this message should be displayed. -1 deactivate the message, 0 always show the message" className={classes.input_marge}  label="Trigger metered message from" source="triggerMessageFrom" />
                                        )
                                    }else {
                                        return null
                                    }
                                }}
                            </FormDataConsumer>
                        </div>
                        <div className={classes.group_input}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData.wall_type === 'regwallAuthPaywall' && formData.wall_system === 'metered'  ) {
                                        return (
                                            <TextInput helperText="default: 3 (Number of free articles added after user Authentication)" className={classes.input_marge}  label="Limit of free articles added after login" source="limitOfFreeArticleAfterLogin" />
                                        )
                                    }else {
                                        return null
                                    }}
                                }
                            </FormDataConsumer>
                        </div>
                    </FormDataContent>
                    <FormDataContent dataContentTitle="Traffic Exposure">
                        <TrafficExposureForm />
                    </FormDataContent>
                    <FormDataContent dataContentTitle="Schedulded maintenance">
                        <ScheduledMaintenanceForm />
                    </FormDataContent>
                    <FormDataContent dataContentTitle="Grant on sites">
                        <ReferenceArrayInput label='Sites' source="grant_on_sites" reference="sites-configs">
                            <AutocompleteArrayInput optionText='site'/>
                        </ReferenceArrayInput>
                    </FormDataContent>
                    <FormDataContent dataContentTitle="My account">
                        <div className={classes.group_input}>
                            <TextInput helperText="ex: https://www.facebook.com/cyclingnews/" className={classes.input_marge} source="facebook_link" label="Facebook link" />
                            <TextInput helperText="ex: https://twitter.com/Cyclingnewsfeed" className={classes.input_marge} source="twitter_link" label="Twitter link" />
                            <TextInput helperText="ex: https://www.instagram.com/cyclingnews_feed/" className={classes.input_marge} source="instagram_link" label="Instagram link" />
                            <TextInput helperText="ex: https://www.youtube.com/c/cyclingnewsmag" className={classes.input_marge} source="youtube_link" label="Youtube link" />
                        </div>
                        <BooleanInput helperText="Display a carousel of magazines on My Account page" label="Enable Recirculation block" source="recirculation_block" />
                        <BooleanInput helperText="Display the account linking input" label="Enable the account/subscription linking" source="link_subscription" />
                    </FormDataContent>
                </FormTab>
                <FormTab label="Providers and links">
                    <FormDataContent dataContentTitle="Bluebird options">
                        <TextInput helperText="ex: ELN" className={classes.input_marge} source="journal" label="Journal code" />
                        <TextInput helperText="ex: 92AB" className={classes.input_marge} source="offercode" label="Offer code" />
                        <TextInput helperText="Link for the CTA button" className={classes.long_input} source="bluebird_buy_sub" label="Buy subscription link" />
                        <TextInput helperText="Link 'at' under the CTA button" className={classes.long_input} source="bluebird_buy_sub_at" label="Link under CTA button" />
                        <TextInput helperText="Link for Manage subscription button" className={classes.long_input} source="bluebird_manage_sub" label="Manage subscription link" />
                    </FormDataContent>
                    <FormDataContent dataContentTitle="CDS options">
                        <div className={classes.group_input}>
                            <BooleanInput helperText="Does it have a CDS provider" className={classes.input_marge} label="CDS Provider" source="cds_provider" />
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData.cds_provider === true ) {
                                        return (
                                            <TextInput helperText="ex: TWE" className={classes.input_marge} source="cds_prod_id" label="CDS Prod ID" />
                                        )
                                    }else {
                                        return null
                                    }}
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData.cds_provider === true ) {
                                        if (typeof formData.main_provider === 'undefined' ) {
                                            formData.main_provider = 'bluebird'
                                        }
                                        return (
                                            <SelectInput source="main_provider"
                                                choices={[
                                                    { id: 'bluebird', name: 'Bluebird' },
                                                    { id: 'cds', name: 'CDS' },
                                                ]}
                                                helperText="Prefered provider in My Account page (Default: Bluebird)"
                                                className={classes.input_marge}
                                            />
                                        )
                                    }else {
                                        return null
                                    }}
                                }
                            </FormDataConsumer>
                        </div>
                        <div className={classes.group_input}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData.cds_provider === true ) {
                                        return (
                                            <div>
                                                <TextInput helperText="Link for the CTA button" className={classes.long_input} source="cds_buy_sub" label="Buy subscription link" />
                                                <TextInput helperText="Link 'at' under the CTA button" className={classes.long_input} source="cds_buy_sub_at" label="Link under CTA button" />
                                                <TextInput helperText="Link for Manage subscription button" className={classes.long_input} source="cds_manage_sub" label="Manage subscription link" />
                                            </div>
                                        )
                                    }else {
                                        return null
                                    }}
                                }
                            </FormDataConsumer>
                        </div>
                    </FormDataContent>
                </FormTab>
                <FormTab label="Layers manager">
                    <LayersManager />
                </FormTab>
                <FormTab label="Tracking">
                    <FormDataContent dataContentTitle="UTM">
                        <ArrayInputContent arrayInputSource="tracking">
                            <TextInput helperText= 'ex: referral' label="utm medium" source="utm_medium" />
                            <TextInput helperText= 'ex: HBP-hardlinks' label="utm campaign" source="utm_campaign" />
                            <TextInput helperText= 'ex: HBP%20Paywall' label="utm source" source="utm_source" />
                            <TextInput disabled helperText= 'This input will be set automatically' label="utm content" source="utm_content" />
                        </ ArrayInputContent >
                    </FormDataContent>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default SiteConfigsCreate;
