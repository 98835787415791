import React, { useState } from 'react';
import {
  TextInput,
  BooleanInput,
  FormDataConsumer,
  useRecordContext
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {green, grey, blue } from '@material-ui/core/colors';
import LayerManager from './LayerManager';
import FormDataContent from './FormDataContent';
import MainLayerPremiumPreview from './MainLayerPremiumPreview';
import RegwallLayerPreview from './RegwallLayerPreview';
import { CompactPicker } from 'react-color';
import TextEditor from './TextEditor';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles({
    section_title: {
        fontSize: '1.2rem',
        marginBottom: '0px'
    },
    section_title_underline: {
        width: '40px',
        height:'6px',
        backgroundColor: blue[400],
        marginBottom: '20px',

    },
    layers_manager_content: {
        display: 'flex',
        marginBottom: '40px',
    },
    layers_manager_zone: {
        width: '33.3%',
        padding: '0px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    color_picker_zone: {
        padding: '0px 20px 20px 20px'
    },
    long_input: {
        width: '65%',
        marginRight: '10%',
    },
    hidden_input: {
        display: 'none'
    },
    text_editor_content: {
        width: '65%'
    },
    color_picker_content: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '1em'
    },
});

const mainPremiumLogoPositionDefault = {
    verticalUp: {
        rowLeft: true,
        rowCenter: false,
        rowRight: false,
    },
    verticalDown: {
        rowLeft: false,
        rowCenter: false,
        rowRight: false,
    }
};

const mainPremiumButtonPositionDefault = {
    verticalUp: {
        rowLeft: null,
        rowCenter: false,
        rowRight: false,
    },
    verticalCenter: {
        rowLeft: true,
        rowCenter: false,
        rowRight: false,
    },
    verticalDown: {
        rowLeft: false,
        rowCenter: false,
        rowRight: false,
    }
}

const mainPremiumBackgroundColorDefault = '#FFFFFF';
const mainPremiumButtonBackgroundColorDefault = '#808080';

const PremiumLayerManager = (props) => {
    const classes = useStyles();
    const record = useRecordContext(props)

    const [mainPremiumLogoPosition, setMainPremiumLogoPosition] = useState(
        record && record.main_premium_logo_position ?
        record.main_premium_logo_position :
        mainPremiumLogoPositionDefault
    );

    const [mainPremiumButtonPosition, setMainPremiumButtonPosition] = useState(
        record && record.main_premium_button_position ?
        record.main_premium_button_position :
        mainPremiumButtonPositionDefault
    );

    const [mainPremiumBackgroundColor, setMainPremiumBackgroundColor] = useState(
        record && record.main_premium_background_color ?
        record.main_premium_background_color :
        mainPremiumBackgroundColorDefault
    );

    const [mainPremiumButtonBackgroundColor, setMainPremiumButtonBackgroundColor] = useState(
        record && record.main_premium_button_background_color ?
        record.main_premium_button_background_color :
        mainPremiumButtonBackgroundColorDefault
    );


    const [mainPremiumLogoPositionPreview, setMainPremiumLogoPositionPreview] = useState(positionForPreview(mainPremiumLogoPosition));
    const [mainPremiumButtonPositionPreview, setMainPremiumButtonPositionPreview] = useState(positionForPreview(mainPremiumButtonPosition));


    const [textBeforeButtonPremium, setTextBeforeButtonPremium] = useState(
        record && record.textBeforeButtonPremium ?
        record.textBeforeButtonPremium :
        ''
    );

    const [textAfterButtonPremium, setTextAfterButtonPremium] = useState(
        record && record.textAfterButtonPremium ?
        record.textAfterButtonPremium :
        ''
    );

    const [premiumManagementExpanded, setPremiumManagementExpanded] = useState(
        record && record.template_main_premium ?
        true : false
    );
    const handleExpandClick = () => {
    setPremiumManagementExpanded(!premiumManagementExpanded);
  };

    function positionForPreview(elementPosition){
        for (const [vKey, vValue] of Object.entries(elementPosition)) {
            for (const [rKey, rValue] of Object.entries(vValue)) {
                if (rValue) {
                    return ({
                        vertical: vKey,
                        row: rKey
                    })
                }
            }
        }
    };


    function handleChangeMainPremiumBackgroundColor(color){
        setMainPremiumBackgroundColor(color.hex);
    };

    function handleChangeMainPremiumButtonBackgroundColor(color){
        setMainPremiumButtonBackgroundColor(color.hex);
    };



    function handleMainPremiumPosition(element, verticalPosition, rowPosition){

        let newLogoPosition = {...mainPremiumLogoPosition};
        let newButtonPosition = {...mainPremiumButtonPosition};


        if (element === 'logo') {
            for (const [vKey, vValue] of Object.entries(newLogoPosition)) {
                for (const [rKey, rValue] of Object.entries(vValue)) {
                    if (vKey === verticalPosition) {
                        if (rKey === rowPosition) {
                            newLogoPosition[vKey][rowPosition] = true;
                            newButtonPosition[vKey][rowPosition] = null;
                        }else if (newLogoPosition[vKey][rKey] && rKey !== rowPosition){
                            newLogoPosition[vKey][rKey] = false;
                            if (newButtonPosition[vKey][rKey] === null) {
                                newButtonPosition[vKey][rKey] = false;
                            }
                        }
                    }else {
                        if (newLogoPosition[vKey][rKey]) {
                            newLogoPosition[vKey][rKey] = false;
                        }
                        if (newButtonPosition[vKey][rKey] === null) {
                            newButtonPosition[vKey][rKey] = false;
                        }
                    }

                }
            }
        }
        if (element === 'button') {
            for (const [vKey, vValue] of Object.entries(newButtonPosition)) {
                for (const [rKey, rValue] of Object.entries(vValue)) {
                    if (vKey === verticalPosition) {
                        if (rKey === rowPosition) {
                            newButtonPosition[vKey][rowPosition] = true;
                            if (vKey in newLogoPosition) {
                                newLogoPosition[vKey][rowPosition] = null;
                            }
                        }else if (newButtonPosition[vKey][rKey] && rKey !== rowPosition){
                            newButtonPosition[vKey][rKey] = false;
                            if (vKey in newLogoPosition) {
                                if (newLogoPosition[vKey][rKey] === null) {
                                    newLogoPosition[vKey][rKey] = false;
                                }
                            }
                        }
                    }else {
                        if (newButtonPosition[vKey][rKey]) {
                            newButtonPosition[vKey][rKey] = false;
                        }
                        if (vKey in newLogoPosition) {
                            if (newLogoPosition[vKey][rKey] === null) {
                                newLogoPosition[vKey][rKey] = false;
                            }
                        }
                    }

                }
            }
        }

        setMainPremiumLogoPosition(newLogoPosition);
        setMainPremiumLogoPositionPreview(positionForPreview(newLogoPosition));

        setMainPremiumButtonPosition(newButtonPosition);
        setMainPremiumButtonPositionPreview(positionForPreview(newButtonPosition));
    };

    return (
        <div   id="main_layer_premium_management">
            <BooleanInput helperText="To add a specific layer for Premium content" label="Add Premium barrier" source="template_main_premium" onClick={handleExpandClick} />
            <Card>
                <Collapse in={premiumManagementExpanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <p className={classes.section_title}>Main layer content PREMIUM</p>
                        <TextInput helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/jtld3qrazv1613402249.svg" className={classes.long_input} source="logo_premium" />
                        <div className={classes.text_editor_content}>
                            <TextEditor editorTitle='Text before button premium' textContent= {textBeforeButtonPremium} handleText= {setTextBeforeButtonPremium}/>
                        </div>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>{
                                if(!formData.wall_type || (formData.wall_type  &&  formData.wall_type !== 'regwall')){
                                    return (
                                        <TextInput helperText="ex: Access now" className={classes.long_input} source="button_text_premium" />
                                    )
                                }else {
                                    return null
                                }
                            }}
                        </FormDataConsumer>
                        <div className={classes.text_editor_content}>
                            <TextEditor editorTitle='Text after button premium'textContent= {textAfterButtonPremium} handleText= {setTextAfterButtonPremium}/>
                        </div>
                        <p className={classes.section_title}>Main Layer customization PREMIUM</p>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>{
                                if(!formData.wall_type || (formData.wall_type  &&  formData.wall_type !== 'regwall')){
                                    return (
                                        <div className={classes.layers_manager_content}>
                                            <div className={classes.layers_manager_zone}>
                                                <LayerManager layer='main' element='logo' elementPosition= {mainPremiumLogoPosition} handlePosition={handleMainPremiumPosition} />
                                            </div>
                                            <div className={classes.layers_manager_zone}>
                                                <LayerManager layer='main' element='button' elementPosition= {mainPremiumButtonPosition} handlePosition={handleMainPremiumPosition} />
                                            </div>
                                            <div className={classes.layers_manager_zone}>
                                                <div className={classes.color_picker_zone}>
                                                    <p className={classes.title}>Choose background color</p>
                                                    <div className={classes.color_picker_content}>
                                                        <CompactPicker color={ mainPremiumBackgroundColor } onChangeComplete={ handleChangeMainPremiumBackgroundColor } />
                                                    </div>
                                                </div>
                                                <div className={classes.color_picker_zone}>
                                                    <p className={classes.title}>Choose button background color</p>
                                                    <div className={classes.color_picker_content}>
                                                        <CompactPicker color={ mainPremiumButtonBackgroundColor } onChangeComplete={ handleChangeMainPremiumButtonBackgroundColor } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }else {
                                    return (
                                        <div className={classes.layers_manager_content}>
                                            <div className={classes.layers_manager_zone}>
                                                <LayerManager layer='main' element='logo' elementPosition= {mainPremiumLogoPosition} handlePosition={handleMainPremiumPosition} />
                                            </div>
                                            <div className={classes.layers_manager_zone}>
                                                <div className={classes.color_picker_zone}>
                                                    <p className={classes.title}>Choose background color</p>
                                                    <div className={classes.color_picker_content}>
                                                        <CompactPicker color={ mainPremiumBackgroundColor } onChangeComplete={ handleChangeMainPremiumBackgroundColor } />
                                                    </div>
                                                </div>
                                                <div className={classes.color_picker_zone}>
                                                    <p className={classes.title}>Choose button background color</p>
                                                    <div className={classes.color_picker_content}>
                                                        <CompactPicker color={ mainPremiumButtonBackgroundColor } onChangeComplete={ handleChangeMainPremiumButtonBackgroundColor } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            }}
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>{
                                formData.main_premium_logo_position = mainPremiumLogoPosition;
                                formData.main_premium_button_position = mainPremiumButtonPosition;
                                formData.main_premium_background_color = mainPremiumBackgroundColor;
                                formData.main_premium_button_background_color = mainPremiumButtonBackgroundColor;
                                formData.textBeforeButtonPremium = textBeforeButtonPremium;
                                formData.textAfterButtonPremium = textAfterButtonPremium;
                                return (
                                    <div>
                                        <TextInput className={classes.hidden_input} source="main_premium_logo_position" />
                                        <TextInput className={classes.hidden_input} source="main_premium_button_position" />
                                        <TextInput className={classes.hidden_input} source="main_premium_background_color" />
                                        <TextInput className={classes.hidden_input} source="main_premium_button_background_color" />
                                        <TextInput className={classes.hidden_input} source="textBeforeButtonPremium" />
                                        <TextInput className={classes.hidden_input} source="textAfterButtonPremium" />
                                    </div>

                                )}
                            }
                        </FormDataConsumer>

                        <p className={classes.section_title}>Main Layer preview PREMIUM</p>
                        <FormDataConsumer>
                        {({ formData, ...rest }) =>{
                            if(!formData.wall_type || (formData.wall_type  &&  formData.wall_type !== 'regwall')){
                                return (
                                    <MainLayerPremiumPreview logoPositionPreview={mainPremiumLogoPositionPreview}
                                                      buttonPositionPreview={mainPremiumButtonPositionPreview}
                                                      backgroundColor={mainPremiumBackgroundColor}
                                                      buttonBackgroundColor={mainPremiumButtonBackgroundColor}
                                    />
                                )
                            }else {
                                return (
                                    <RegwallLayerPreview logoPositionPreview={mainPremiumLogoPositionPreview}
                                                      buttonPositionPreview={mainPremiumButtonPositionPreview}
                                                      backgroundColor={mainPremiumBackgroundColor}
                                                      buttonBackgroundColor={mainPremiumButtonBackgroundColor}
                                    />
                                )
                            }
                        }}
                        </FormDataConsumer>

                    </CardContent>
                </Collapse>
            </Card>
        </div>
    );
};


export default PremiumLayerManager;
