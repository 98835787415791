import React, { useState, useCallback } from "react";
import useTemplateEngine from "../hooks/useTemplateEngine";
import Grid from "./Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "./IconButton";
import Computer from "@material-ui/icons/Computer";
import Tablet from "@material-ui/icons/Tablet";
import MobileFriendly from "@material-ui/icons/MobileFriendly";
import { makeStyles } from "@material-ui/core/styles";
import { useInput } from "react-admin";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const useStyles = makeStyles((props) => ({
  wrapperInitialPreview: {
    marginBottom: "20px",
    width: "100%",
    height: "10vh",
    marginTop: "15px",
    marginBottom: "15px",
    border: "1px black solid",
    padding: "10px",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  previewTransition: {
    transition: "height .3s",
  },
  wrapperOpenPreview: {
    width: "100%",
    height: "100vh",
  },
  previewWrapperSizer: {
    marginBottom: "20px",
  },
  iframeClass: {
    display: "block",
    border: "1px lightgrey solid",
    margin: "auto",
    overflowY: "scroll",
    resize: "both",
    overflow: "auto",
  },
}));

const PreviewArea = (props) => {
  const {
    input: { name, value },
  } = useInput({ source: props.source });

  let template_content = props.injectValue
    ? props.template
    : `${value[props.css] || ``} ${value[props.template] || ""}`;
  let preview_data = props.injectValue
    ? props.previewData
    : value[props.previewData];

  try {
    var parsed = props.parseDataFromJson
      ? JSON.parse(preview_data)
      : props.previewData;
  } catch (error) {
    console.log(error);

    template_content = "Preview data content an error";
  }

  const template = useTemplateEngine(template_content, parsed ?? "");

  const classesStyle = useStyles();
  const [sizing, setSizing] = useState({
    width: "100%",
  });
  const [iframeMaxWidth, setIframeMaxWidth] = useState("100%");
  const [iframeMaxHeight, setIframeMaxHeight] = useState("750px");

  const [isDisplayingPreview, setIsDisplayingPreview] = useState(false);
  let previewWrapperClass = clsx({
    [classesStyle.wrapperInitialPreview]: true,
    [classesStyle.previewTransition]: isDisplayingPreview,
    [classesStyle.wrapperOpenPreview]: isDisplayingPreview,
  });

  let previewIframeClass = clsx({
    [classesStyle.iframeClass]: true,
    [classesStyle.previewTransition]: isDisplayingPreview,
    [classesStyle.wrapperOpenPreview]: isDisplayingPreview,
  });
  const handleTogglePreview = useCallback(() => {
    setIsDisplayingPreview(!isDisplayingPreview);
  }, [isDisplayingPreview]);

  const handleMediaQueryChange = useCallback((width) => {
    setSizing({ width: width });
  }, []);

  return (
    <div className={previewWrapperClass}>
      <Button onClick={handleTogglePreview} color="primary" gutterBottom>
        Preview
      </Button>
      {isDisplayingPreview && (
        <div>
          <Grid columns={3} classes={classesStyle.previewWrapperSizer}>
            <IconButton
              color="primary"
              icon={(classes) => (
                <MobileFriendly className={classes.leftIcon} />
              )}
              onClick={() => handleMediaQueryChange(425)}
            >
              Mobile
            </IconButton>
            <IconButton
              color="primary"
              icon={(classes) => <Tablet className={classes.leftIcon} />}
              onClick={() => handleMediaQueryChange(670)}
            >
              Tablet
            </IconButton>
            <IconButton
              color="primary"
              icon={(classes) => <Computer className={classes.leftIcon} />}
              onClick={() => handleMediaQueryChange("100%")}
            >
              Desktop
            </IconButton>
          </Grid>
          <iframe
            id="preview-iframe"
            height={sizing.height}
            style={{
              maxWidth: iframeMaxWidth,
              maxHeight: iframeMaxHeight,
              height: iframeMaxHeight,
              ...sizing,
            }}
            srcdoc={template}
            className={previewIframeClass}
            frameborder="0"
          />
        </div>
      )}
    </div>
  );
};

export default PreviewArea;
