import React, { useState } from 'react';
import {
  TextInput,
  FormDataConsumer,
  useRecordContext
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import {green, grey, blue } from '@material-ui/core/colors';
import LayerManager from './LayerManager';
import FormDataContent from './FormDataContent';
import StickyRegwallAuthLayerPreview from './StickyRegwallAuthLayerPreview';
import { CompactPicker } from 'react-color';
import TextEditor from './TextEditor';

const useStyles = makeStyles({
    section_title: {
        fontSize: '1.2rem',
        marginBottom: '0px'
    },
    section_title_underline: {
        width: '40px',
        height:'6px',
        backgroundColor: blue[400],
        marginBottom: '20px',

    },
    layers_manager_content: {
        display: 'flex',
        marginBottom: '40px',
    },
    layers_manager_zone: {
        width: '33.3%',
        padding: '0px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    color_picker_zone: {
        padding: '0px 20px 20px 20px'
    },
    long_input: {
        width: '65%',
        marginRight: '10%',
    },
    hidden_input: {
        display: 'none'
    },
    text_editor_content: {
        width: '65%'
    },
    color_picker_content: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '1em'
    },
});
const stickyRegwallAuthLogoPositionDefault = {
    verticalUp: {
        rowLeft: false,
        rowCenter: false,
        rowRight: true,
    }
};

const stickyRegwallAuthBackgroundColorDefault = '#FFFFFF';
const stickyRegwallAuthButtonBackgroundColorDefault = '#808080';

const StickyRegwallAuthLayerManager = (props) => {
    const classes = useStyles();
    const record = useRecordContext(props)
    const [stickyRegwallAuthLogoPosition, setStickyRegwallAuthLogoPosition] = useState(
        record && record.sticky_regwall_auth_logo_position ?
        record.sticky_regwall_auth_logo_position :
        stickyRegwallAuthLogoPositionDefault
    );

    const [stickyRegwallAuthBackgroundColor, setStickyRegwallAuthBackgroundColor] = useState(
        record && record.sticky_regwall_auth_background_color ?
        record.sticky_regwall_auth_background_color :
        stickyRegwallAuthBackgroundColorDefault
    );
    const [stickyRegwallAuthButtonBackgroundColor, setStickyRegwallAuthButtonBackgroundColor] = useState(
        record && record.sticky_regwall_auth_button_background_color ?
        record.sticky_regwall_auth_button_background_color :
        stickyRegwallAuthButtonBackgroundColorDefault
    );

    const [stickyRegwallAuthLogoPositionPreview, setStickyRegwallAuthLogoPositionPreview] = useState(positionForPreview(stickyRegwallAuthLogoPosition));

    const [textStickyRegwallAuthBeforeButton, setTextStickyRegwallAuthBeforeButton] = useState(
        record && record.textStickyRegwallAuthBeforeButton ?
        record.textStickyRegwallAuthBeforeButton :
        ''
    );

    function positionForPreview(elementPosition){
        for (const [vKey, vValue] of Object.entries(elementPosition)) {
            for (const [rKey, rValue] of Object.entries(vValue)) {
                if (rValue) {
                    return ({
                        vertical: vKey,
                        row: rKey
                    })
                }
            }
        }
    };


    function handleChangeStickyRegwallAuthBackgroundColor(color){
        setStickyRegwallAuthBackgroundColor(color.hex);
    };

    function handleChangeStickyRegwallAuthButtonBackgroundColor(color){
        setStickyRegwallAuthButtonBackgroundColor(color.hex);
    };

    function handleStickyRegwallAuthPosition(element, verticalPosition, rowPosition){

        let newLogoPosition = {...stickyRegwallAuthLogoPosition};


        if (element === 'logo') {
            for (const [vKey, vValue] of Object.entries(newLogoPosition)) {
                for (const [rKey, rValue] of Object.entries(vValue)) {
                    if (vKey === verticalPosition) {
                        if (rKey === rowPosition) {
                            newLogoPosition[vKey][rowPosition] = true;
                        }else if (newLogoPosition[vKey][rKey] && rKey !== rowPosition){
                            newLogoPosition[vKey][rKey] = false;
                        }
                    }else {
                        if (newLogoPosition[vKey][rKey]) {
                            newLogoPosition[vKey][rKey] = false;
                        }
                    }

                }
            }
        }

        setStickyRegwallAuthLogoPosition(newLogoPosition);
        setStickyRegwallAuthLogoPositionPreview(positionForPreview(newLogoPosition));
    };
    return (
        <div>
            <FormDataContent dataContentTitle="Sticky Regwall layer content (bottom page)">
                <TextInput helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/m7awu8serc1613169967.svg" className={classes.long_input} source="logo_small_regwall_auth" />
                <div className={classes.text_editor_content}>
                    <TextEditor editorTitle='Text sticky before button' textContent= {textStickyRegwallAuthBeforeButton} handleText= {setTextStickyRegwallAuthBeforeButton} />
                </div>
                <TextInput helperText="ex: Access now" className={classes.long_input} source="sticky_regwall_auth_button_text" />
            </FormDataContent>
            <p className={classes.section_title}>Sticky Regwall Layer customization</p>
            <div className={classes.section_title_underline}></div>
            <div className={classes.layers_manager_content}>
                <div className={classes.layers_manager_zone}>
                    <LayerManager layer='sticky' element='logo' elementPosition={stickyRegwallAuthLogoPosition} handlePosition={handleStickyRegwallAuthPosition} />
                </div>
                <div className={classes.layers_manager_zone}>
                    <div className={classes.color_picker_zone}>
                        <p className={classes.title}>Choose background color</p>
                        <div className={classes.color_picker_content}>
                            <CompactPicker color={ stickyRegwallAuthBackgroundColor } onChangeComplete={ handleChangeStickyRegwallAuthBackgroundColor } />
                        </div>
                    </div>
                    <div className={classes.color_picker_zone}>
                        <p className={classes.title}>Choose button background color</p>
                        <div className={classes.color_picker_content}>
                            <CompactPicker color={ stickyRegwallAuthButtonBackgroundColor } onChangeComplete={ handleChangeStickyRegwallAuthButtonBackgroundColor } />
                        </div>
                    </div>
                </div>
            </div>
            <FormDataConsumer>
                {({ formData, ...rest }) =>{
                    formData.sticky_regwall_auth_logo_position = stickyRegwallAuthLogoPosition;
                    formData.sticky_regwall_auth_background_color = stickyRegwallAuthBackgroundColor;
                    formData.sticky_regwall_auth_button_background_color = stickyRegwallAuthButtonBackgroundColor;
                    formData.textStickyRegwallAuthBeforeButton = textStickyRegwallAuthBeforeButton;
                    return (
                        <div>
                            <TextInput className={classes.hidden_input} source="sticky_regwall_auth_logo_position" />
                            <TextInput className={classes.hidden_input} source="sticky_regwall_auth_background_color" />
                            <TextInput className={classes.hidden_input} source="sticky_regwall_auth_button_background_color" />
                            <TextInput className={classes.hidden_input} source="textStickyRegwallAuthBeforeButton" />
                        </div>
                    )}
                }
            </FormDataConsumer>
            <p className={classes.section_title}>Sticky Regwall Layer preview</p>
            <div className={classes.section_title_underline}></div>
            <StickyRegwallAuthLayerPreview logoPositionPreview={stickyRegwallAuthLogoPositionPreview}
                                backgroundColor={stickyRegwallAuthBackgroundColor}
                                buttonBackgroundColor={stickyRegwallAuthButtonBackgroundColor}
            />
        </div>
    );
};

export default StickyRegwallAuthLayerManager;
