import React, { useState } from 'react';
import {
    TextInput,
    FormDataConsumer, BooleanInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import {green, grey, blue } from '@material-ui/core/colors';
import LayerManager from './LayerManager';
import FormDataContent from './FormDataContent';
import MainLayerPreview from './MainLayerPreview';
import RegwallLayerPreview from './RegwallLayerPreview';
import StickyLayerPreview from './StickyLayerPreview';
import RegwallStickyLayerPreview from './RegwallStickyLayerPreview';
import { CompactPicker } from 'react-color';
import TextEditor from './TextEditor';
import PremiumLayerManager from './PremiumLayerManager';
import RegwallAuthLayerManager from './RegwallAuthLayerManager';
import MainLayerManager from './MainLayerManager';
import StickyLayerManager from './StickyLayerManager';
import Layer from "./Layer";
import {Field, useFormState} from "react-final-form";
import StickyRegwallAuthLayerManager from './StickyRegwallAuthLayerManager';

const useStyles = makeStyles({
    long_input: {
        width: '65%',
        marginRight: '10%',
    }
});
const LayersManager = (props) => {
    const classes = useStyles();
    const { values: formdata } = useFormState();

    return (
        <div>
            <FormDataContent dataContentTitle="Main layer">
                <BooleanInput source={'main_layer_enabled'} label={'Use custom Template'} defaultValue={false}/>
                <FormDataConsumer>
                    {({formData, ...rest}) =>
                        formData['main_layer_enabled'] &&
                        <Field
                            name={"Main layer"}
                            source={"main_layer"}
                            height="100%"
                            width="100%"
                            component={Layer}
                        />
                    }
                </FormDataConsumer>
                { !formdata.main_layer_enabled && <MainLayerManager/>}
            </FormDataContent>

            <FormDataContent dataContentTitle="Main layer premium">
                { !formdata.main_layer_premium_enabled && <PremiumLayerManager/> }
                <BooleanInput source={'main_layer_premium_enabled'} label={'Add Premium barrier and use custom Template'} defaultValue={false}/>
                <FormDataConsumer>
                    {({formData, ...rest}) =>
                        formData['main_layer_premium_enabled'] &&
                        <Field
                            name={"Main layer premium"}
                            source={"main_layer_premium"}
                            height="100%"
                            width="100%"
                            component={Layer}
                        />
                    }
                </FormDataConsumer>
            </FormDataContent>

            {
                formdata.wall_type  &&  formdata.wall_type === 'regwallAuthPaywall' &&
                <FormDataContent dataContentTitle="Regwall layer">
                    <BooleanInput source={'regwall_layer_enabled'} label={'Use custom Template'} defaultValue={false}/>
                    <FormDataConsumer>
                        {({formData, ...rest}) =>
                            formData['regwall_layer_enabled'] &&
                            <Field
                                name={"Regwall layer"}
                                source={"regwall_layer"}
                                height="100%"
                                width="100%"
                                component={Layer}
                            />
                        }
                    </FormDataConsumer>
                    {!formdata.regwall_layer_enabled && <RegwallAuthLayerManager/>}
                    <BooleanInput source={'newsletter_custom_layer_enabled'} label={'Use custom Template with control tag'} defaultValue={false}/>
                    <FormDataConsumer>
                        {({formData, ...rest}) =>
                            formData['newsletter_custom_layer_enabled'] &&
                            <Field
                                name={"Newsletter custom layer"}
                                source={"newsletter_custom_layer"}
                                height="100%"
                                width="100%"
                                component={Layer}
                            />
                        }
                    </FormDataConsumer>
                    {!formdata.regwall_layer_enabled && <RegwallAuthLayerManager/>}
                </FormDataContent>
            }
            <BooleanInput helperText="Display a sticky layer on the bottom of the page when scrolling down after the main barrier" label="Enable sticky template" source="template_sticky" />
            {
                formdata.template_sticky  &&  formdata.template_sticky === true &&
                <FormDataContent dataContentTitle="Sticky layer">
                    <BooleanInput source={'sticky_layer_enabled'} label={'Use custom Template'} defaultValue={false}/>
                    <FormDataConsumer>
                        {({formData, ...rest}) =>
                            formData['sticky_layer_enabled'] &&
                            <Field
                                name={"Sticky layer"}
                                source={"sticky_layer"}
                                height="100%"
                                width="100%"
                                component={Layer}
                            />
                        }
                    </FormDataConsumer>
                    { !formdata.sticky_layer_enabled && <StickyLayerManager/>}
                </FormDataContent>
            }

            {
                formdata.template_sticky  &&  formdata.template_sticky === true && formdata.wall_type  &&  formdata.wall_type === 'regwallAuthPaywall' &&
                <FormDataContent dataContentTitle="Sticky regwall layer">
                    <BooleanInput source={'sticky_regwall_layer_enabled'} label={'Use custom Template'} defaultValue={false}/>
                    <FormDataConsumer>
                        {({formData, ...rest}) =>
                            formData['sticky_regwall_layer_enabled'] &&
                            <Field
                                name={"Sticky Regwall layer"}
                                source={"sticky_regwall_layer"}
                                height="100%"
                                width="100%"
                                component={Layer}
                            />
                        }
                    </FormDataConsumer>
                    {!formdata.sticky_regwall_layer_enabled && <StickyRegwallAuthLayerManager/>}
                </FormDataContent>
            }
            <BooleanInput helperText="Display a dropdown menu in the header if available for this site"  label="Enable header menu template" source="template_menu_header" />
            <FormDataContent dataContentTitle="Add specific css (Advanced)">
                <TextInput multiline minRows='10' className={classes.long_input} source="specific_css" />
            </FormDataContent>
        </div>
    );
};

export default LayersManager;
