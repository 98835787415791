import React from 'react';
import {
  TextField, SimpleShowLayout, Show
} from 'react-admin';

const linkContainerStyle = {
  backgroundColor: 'rgb(160, 201, 219)',
  color: 'white',
  borderRadius: '3px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px',
  boxShadow: '1px 1px 5px grey',
  alignItems: 'center',
  marginBottom: '14px',
};
const goToLookerLoginCtaStyle = {
  border: '1px solid white',
  borderRadius: '5px',
  padding: '8px 12px',
  textDecoration: 'none',
  color: 'white',
};
const dashBoardMainSection = {
  width: '100%'
}
const dashboardMainTitleStyle = {

}

export const Dashboard = (props) => (
  <section style={dashBoardMainSection} className='dashboardMainSection'>
    <div style={linkContainerStyle}>
      <div style={dashboardMainTitleStyle}>PAYWALL LOOKER DASHBOARD</div>
      <a style={goToLookerLoginCtaStyle} href='https://futureplc.cloud.looker.com/login' target='_blank'>
        Click here to authenticate to Looker
      </a>
    </div>
    <iframe
      src="https://futureplc.cloud.looker.com/embed/dashboards/67?Channel=&Date+Range=30+day&Device+Category=&Country=&Logged+In=&Currency=&Term+Length=&Paymethod=&Choose+date+aggregation+level=DAY&allow_login_screen=true"
      height="900px"
      width="100%"
      frameborder="0">
    </iframe>
  </section>
);