import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { useFormState } from 'react-final-form';

const RegwallStickyLayerPreview = (props) => {
    const { values } = useFormState();

    const useStyles = makeStyles({
        preview_content: {
            padding: '20px',
            border: '1px solid #E8E8E8',
            marginBottom: '50px',
        },
        kiosq_sticky_layer: {
            display: 'flex',
            justifyContent: 'center'
        },
        kiosq_sticky_layer_container: {
            padding: '20px',
            width: '602px',
            padding: '10px',
            marginBottom: '0px',
        },
        kiosq_sticky_logo_rowLeft: {
            margin: '0px',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        kiosq_sticky_logo_rowCenter: {
            margin: '0px',
            display: 'flex',
            justifyContent: 'center',
        },
        kiosq_sticky_logo_rowRight: {
            margin: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        kiosq_sticky_logo_img: {
            width: '70px',
        },
        kiosq_login_button: {
            marginBottom: '0px',
            fontSize: '13px',
        },
        kiosq_login_button_button: {
            height: '34px',
            width: '310px',
            textAlign: 'center',
            color: '#fff',
            marginBottom: '10px',
            border: 'none',
            fontWeight: '700',
            textTransform: 'uppercase',
            cursor: 'pointer',
            marginRight: '10px',
        },
        kiosq_description: {
            fontSize: '14px',
            lineHeight: '22px',
            marginBottom: '25px',
            '& p' : {
                margin: '0px',
            },
            '& h1' : {
                marginBottom: '0.5rem',
                fontSize: '28px',
                lineHeight: '34px',
                marginTop: '0px',
            },
            '& h2' : {
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: '24px',
                marginBottom: '10px',
                marginTop: '0px',
            },
            '& h3' : {
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '24px',
                marginBottom: '10px',
                marginTop: '0px',
            }
        },
    });
    const classes = useStyles();

    return (
            <div className={classes.preview_content}>
                <div className={classes.kiosq_sticky_layer}>
                    <div className={classes.kiosq_sticky_layer_container} style={{backgroundColor: props.backgroundColor}}>
                        { values.logo_small !== undefined && values.logo_small !== null && props.logoPositionPreview.vertical === 'verticalUp' ?
                          <div className={
                                  props.logoPositionPreview.row === 'rowLeft'?
                                  classes.kiosq_sticky_logo_rowLeft :
                                  props.logoPositionPreview.row === 'rowCenter'?
                                  classes.kiosq_sticky_logo_rowCenter :
                                  classes.kiosq_sticky_logo_rowRight
                              }
                          >
                              <img className={classes.kiosq_sticky_logo_img} src={ values.logo_small } />
                          </div> :
                          null
                        }

                        <div className={classes.kiosq_description}>
                            { (values.textStickyBeforeButton !== undefined && values.textStickyBeforeButton !== null) && parse(values.textStickyBeforeButton)  }
                        </div>
                        <div className={classes.kiosq_login_button}>
                            <button className={classes.kiosq_login_button_button} style={{backgroundColor: props.buttonBackgroundColor}}>SIGN UP</button>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default RegwallStickyLayerPreview;
