import React from "react";
import {
    AutocompleteInput,
    ReferenceInput,
    useQueryWithStore,
    FormDataConsumer,
    SelectInput
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import PreviewArea from "./PreviewArea";
import {Field, useForm, useFormState} from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import useTemplateEngine from "../hooks/useTemplateEngine";
import Grid from "./Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    text_editor_content: {
        width: '65%'
    },
    input_data: {
        width: '320px',
        height: '30px',
        padding: '0px 20px',
        margin: '30px 8px 0',
        boxSizing: 'border-box'
    },
    input_data_value: {
        width: '500px',
        height: '40px',
        padding: '0px 20px',
        margin: '30px 8px 0',
        boxSizing: 'border-box'
    },
    button_remove: {
        color: 'red',
        width: '150px',
        marginLeft: '20px'
    },
    custom_template_select: {
      width: '300px'
    },
    button_add: {
        marginTop: '40px',
        marginBottom: '40px'
    },
    button_select_custom_template: {
        height: '40px',
        marginTop: '10px'
    }
});

const Layer = (props) => {
    const classes = useStyles();

    const { values: formdata } = useFormState();

    //Get html render from offers
    const {
        data: OfferdataQuery,
    } = useQueryWithStore({
        type: "getOne",
        resource: "custom-templates",
        payload: { id: formdata.offer_custom_template },
    });

    const OffersRender = useTemplateEngine(
        `${OfferdataQuery?.css_template || ``} ${OfferdataQuery?.template || ``}`,
        {offers_items: formdata?.offers || [], offers_name: formdata.offers_name || ''}
    );

    const {
        data: dataQuery,
    } = useQueryWithStore({
        type: "getOne",
        resource: "custom-templates",
        payload: { id: formdata[props.source + '_custom_template'] },
    });
    const htmlTemplate = `${dataQuery?.css_template || ``} ${dataQuery?.template || ``}`;
    const match = [...htmlTemplate.matchAll('{{([a-zA-Z]+)}}|{{defaultValue ([a-zA-Z]+) \'.*\'}}')];
    const placeholder = match.map((e) => {
        let value = e['1'] ?? e['2'];
        if (value === 'offers') {
            return;
        }

       return {id: value, name: value};
    });

    var data = {'offers': OffersRender};
    const handleChange = (formData, fields, index, source) => {
        fields.map((field) => {
            data[formData[source][index]['property']] = formData[source][index]['value']
        });
    };

    return (
        <div>
            <FormDataConsumer>
                {({formData, ...rest}) =>
                    <div>
                        <Grid columns={2} width={'600px'}>
                                <ReferenceInput
                                    alwaysOn
                                    source={`${props.source}_custom_template`}
                                    label="Custom template"
                                    reference="custom-templates"
                                    perPage={1000}
                                    resource={""}
                                    filter={{tag: "layer"}}
                                    className={classes.custom_template_select}
                                >
                                    <AutocompleteInput
                                        optionText="headline"
                                        alwaysOn
                                        resettable
                                    />
                                </ReferenceInput>
                            {formdata[props.source + '_custom_template'] &&
                                <Button variant="contained" color={'primary'}
                                        className={classes.button_select_custom_template}
                                        href={'#/custom-templates/' + formdata[`${props.source}_custom_template`]}
                                >
                                    Edit Custom template
                                </Button>
                            }
                        </Grid>
                        {formdata[props.source + '_custom_template'] &&
                            <div>
                                <FieldArray mylabel="Data" name={props.source}>
                                    {({fields}) => (
                                        <div>
                                            {fields.map((name, index) => (
                                                <div key={name}>
                                                    <div>
                                                        {placeholder.length > 0 &&
                                                            <SelectInput className={classes.input_data}
                                                                         name={`${name}.property`}
                                                                         source={`${name}.property`}
                                                                         choices={placeholder}
                                                                         onBlur={handleChange(formData, fields, index, props.source)}
                                                                         label={'PlaceHolder'}
                                                            />
                                                        }
                                                        <Field className={classes.input_data_value} name={`${name}.value`}
                                                               component="input"/>
                                                        <Button variant="outlined"
                                                                color={'inherit'}
                                                                className={classes.button_remove}
                                                                onClick={() => fields.remove(index)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}

                                            <Button variant="contained"
                                                    color={'primary'}
                                                    className={classes.button_add}
                                                    onClick={() => fields.push({property: '', value: ''})}
                                            >
                                                Add new property/value
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>
                                <Field
                                name={"preview_area"}
                                height="100%"
                                width="100%"
                                component={PreviewArea}
                                template={htmlTemplate}
                                previewData={data}
                                parseDataFromJson={false}
                                injectValue={true}
                                />
                            </div>
                        }
                    </div>
                }
            </FormDataConsumer>
        </div>
    )
};

export default Layer;