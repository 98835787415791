import React, { useState } from 'react';
import {
  TextInput,
  FormDataConsumer,
  useRecordContext
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {green, grey, blue } from '@material-ui/core/colors';
import LayerManager from './LayerManager';
import FormDataContent from './FormDataContent';
import MainLayerPreview from './MainLayerPreview';
import RegwallLayerPreview from './RegwallLayerPreview';
import { CompactPicker } from 'react-color';
import TextEditor from './TextEditor';

const useStyles = makeStyles({
    section_title: {
        fontSize: '1.2rem',
        marginBottom: '0px'
    },
    section_title_underline: {
        width: '40px',
        height:'6px',
        backgroundColor: blue[400],
        marginBottom: '20px',

    },
    layers_manager_content: {
        display: 'flex',
        marginBottom: '40px',
    },
    layers_manager_zone: {
        width: '33.3%',
        padding: '0px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    color_picker_zone: {
        padding: '0px 20px 20px 20px'
    },
    long_input: {
        width: '65%',
        marginRight: '10%',
    },
    hidden_input: {
        display: 'none'
    },
    text_editor_content: {
        width: '65%'
    },
    color_picker_content: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '1em'
    },
});
const mainLogoPositionDefault = {
    verticalUp: {
        rowLeft: true,
        rowCenter: false,
        rowRight: false,
    },
    verticalDown: {
        rowLeft: false,
        rowCenter: false,
        rowRight: false,
    }
};

const mainButtonPositionDefault = {
    verticalUp: {
        rowLeft: null,
        rowCenter: false,
        rowRight: false,
    },
    verticalCenter: {
        rowLeft: true,
        rowCenter: false,
        rowRight: false,
    },
    verticalDown: {
        rowLeft: false,
        rowCenter: false,
        rowRight: false,
    }
}
const mainBackgroundColorDefault = '#FFFFFF';
const mainButtonBackgroundColorDefault = '#808080';

const MainLayerManager = (props) => {
    const classes = useStyles();
    const record = useRecordContext(props)

    const [mainLogoPosition, setMainLogoPosition] = useState(
        record && record.main_logo_position ?
        record.main_logo_position :
        mainLogoPositionDefault
    );
    const [mainButtonPosition, setMainButtonPosition] = useState(
        record && record.main_button_position ?
        record.main_button_position :
        mainButtonPositionDefault
    );
    const [mainBackgroundColor, setMainBackgroundColor] = useState(
        record && record.main_background_color ?
        record.main_background_color :
        mainBackgroundColorDefault
    );
    const [mainButtonBackgroundColor, setMainButtonBackgroundColor] = useState(
        record && record.main_button_background_color ?
        record.main_button_background_color :
        mainButtonBackgroundColorDefault
    );

    const [mainLogoPositionPreview, setMainLogoPositionPreview] = useState(positionForPreview(mainLogoPosition));
    const [mainButtonPositionPreview, setMainButtonPositionPreview] = useState(positionForPreview(mainButtonPosition));

    const [textBeforeButton, setTextBeforeButton] = useState(
        record && record.textBeforeButton ?
        record.textBeforeButton :
        ''
    );
    const [textAfterButton, setTextAfterButton] = useState(
        record && record.textAfterButton ?
        record.textAfterButton :
        ''
    );

    function positionForPreview(elementPosition){
        for (const [vKey, vValue] of Object.entries(elementPosition)) {
            for (const [rKey, rValue] of Object.entries(vValue)) {
                if (rValue) {
                    return ({
                        vertical: vKey,
                        row: rKey
                    })
                }
            }
        }
    };

    function handleChangeMainBackgroundColor(color){
        setMainBackgroundColor(color.hex);
    };
    function handleChangeMainButtonBackgroundColor(color){
        setMainButtonBackgroundColor(color.hex);
    };

    function handleMainPosition(element, verticalPosition, rowPosition){

        let newLogoPosition = {...mainLogoPosition};
        let newButtonPosition = {...mainButtonPosition};


        if (element === 'logo') {
            for (const [vKey, vValue] of Object.entries(newLogoPosition)) {
                for (const [rKey, rValue] of Object.entries(vValue)) {
                    if (vKey === verticalPosition) {
                        if (rKey === rowPosition) {
                            newLogoPosition[vKey][rowPosition] = true;
                            newButtonPosition[vKey][rowPosition] = null;
                        }else if (newLogoPosition[vKey][rKey] && rKey !== rowPosition){
                            newLogoPosition[vKey][rKey] = false;
                            if (newButtonPosition[vKey][rKey] === null) {
                                newButtonPosition[vKey][rKey] = false;
                            }
                        }
                    }else {
                        if (newLogoPosition[vKey][rKey]) {
                            newLogoPosition[vKey][rKey] = false;
                        }
                        if (newButtonPosition[vKey][rKey] === null) {
                            newButtonPosition[vKey][rKey] = false;
                        }
                    }

                }
            }
        }
        if (element === 'button') {
            for (const [vKey, vValue] of Object.entries(newButtonPosition)) {
                for (const [rKey, rValue] of Object.entries(vValue)) {
                    if (vKey === verticalPosition) {
                        if (rKey === rowPosition) {
                            newButtonPosition[vKey][rowPosition] = true;
                            if (vKey in newLogoPosition) {
                                newLogoPosition[vKey][rowPosition] = null;
                            }
                        }else if (newButtonPosition[vKey][rKey] && rKey !== rowPosition){
                            newButtonPosition[vKey][rKey] = false;
                            if (vKey in newLogoPosition) {
                                if (newLogoPosition[vKey][rKey] === null) {
                                    newLogoPosition[vKey][rKey] = false;
                                }
                            }
                        }
                    }else {
                        if (newButtonPosition[vKey][rKey]) {
                            newButtonPosition[vKey][rKey] = false;
                        }
                        if (vKey in newLogoPosition) {
                            if (newLogoPosition[vKey][rKey] === null) {
                                newLogoPosition[vKey][rKey] = false;
                            }
                        }
                    }

                }
            }
        }

        setMainLogoPosition(newLogoPosition);
        setMainLogoPositionPreview(positionForPreview(newLogoPosition));

        setMainButtonPosition(newButtonPosition);
        setMainButtonPositionPreview(positionForPreview(newButtonPosition));
    };

    return (
        <div>
                <div className={classes.text_editor_content}>
                    <TextEditor editorTitle='Text before button' textContent= {textBeforeButton} handleText= {setTextBeforeButton} />
                </div>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>{
                        if(!formData.wall_type || (formData.wall_type  &&  formData.wall_type !== 'regwall')){
                            return (
                                <TextInput helperText="ex: Access now" className={classes.long_input} source="button_text" />
                            )
                        }else {
                            return null
                        }
                    }}
                </FormDataConsumer>
                <div className={classes.text_editor_content}>
                    <TextEditor editorTitle='Text after button'textContent= {textAfterButton} handleText= {setTextAfterButton} />
                </div>
            <p className={classes.section_title}>Main Layer customization</p>
            <FormDataConsumer>
                {({ formData, ...rest }) =>{
                    if(!formData.wall_type || (formData.wall_type  &&  formData.wall_type !== 'regwall')){
                        return (
                            <div className={classes.layers_manager_content}>
                                <div className={classes.layers_manager_zone}>
                                    <LayerManager layer='main' element='logo' elementPosition= {mainLogoPosition} handlePosition={handleMainPosition} />
                                </div>
                                <div className={classes.layers_manager_zone}>
                                    <LayerManager layer='main' element='button' elementPosition= {mainButtonPosition} handlePosition={handleMainPosition}/>
                                </div>
                                <div className={classes.layers_manager_zone}>
                                    <div className={classes.color_picker_zone}>
                                        <p className={classes.title}>Choose background color</p>
                                        <div className={classes.color_picker_content}>
                                            <CompactPicker color={ mainBackgroundColor } onChangeComplete={ handleChangeMainBackgroundColor } />
                                        </div>
                                    </div>
                                    <div className={classes.color_picker_zone}>
                                        <p className={classes.title}>Choose button background color</p>
                                        <div className={classes.color_picker_content}>
                                            <CompactPicker color={ mainButtonBackgroundColor } onChangeComplete={ handleChangeMainButtonBackgroundColor } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else {
                        return (
                            <div className={classes.layers_manager_content}>
                                <div className={classes.layers_manager_zone}>
                                    <LayerManager layer='main' element='logo' elementPosition= {mainLogoPosition} handlePosition={handleMainPosition} />
                                </div>
                                <div className={classes.layers_manager_zone}>
                                    <div className={classes.color_picker_zone}>
                                        <p className={classes.title}>Choose background color</p>
                                        <div className={classes.color_picker_content}>
                                            <CompactPicker color={ mainBackgroundColor } onChangeComplete={ handleChangeMainBackgroundColor } />
                                        </div>
                                    </div>
                                    <div className={classes.color_picker_zone}>
                                        <p className={classes.title}>Choose button background color</p>
                                        <div className={classes.color_picker_content}>
                                            <CompactPicker color={ mainButtonBackgroundColor } onChangeComplete={ handleChangeMainButtonBackgroundColor } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>{
                    formData.main_logo_position = mainLogoPosition;
                    formData.main_button_position = mainButtonPosition;
                    formData.main_background_color = mainBackgroundColor;
                    formData.main_button_background_color = mainButtonBackgroundColor;
                    formData.textBeforeButton = textBeforeButton;
                    formData.textAfterButton = textAfterButton;
                    return (
                        <div>
                            <TextInput className={classes.hidden_input} source="main_logo_position" />
                            <TextInput className={classes.hidden_input} source="main_button_position" />
                            <TextInput className={classes.hidden_input} source="main_background_color" />
                            <TextInput className={classes.hidden_input} source="main_button_background_color" />
                            <TextInput className={classes.hidden_input} source="textBeforeButton" />
                            <TextInput className={classes.hidden_input} source="textAfterButton" />
                        </div>

                    )}
                }
            </FormDataConsumer>
            <p className={classes.section_title}>Main Layer preview</p>
            <FormDataConsumer>
            {({ formData, ...rest }) =>{
                if(!formData.wall_type || (formData.wall_type  &&  formData.wall_type !== 'regwall')){
                    return (
                        <MainLayerPreview logoPositionPreview={mainLogoPositionPreview}
                                          buttonPositionPreview={mainButtonPositionPreview}
                                          backgroundColor={mainBackgroundColor}
                                          buttonBackgroundColor={mainButtonBackgroundColor}
                        />
                    )
                }else {
                    return (
                        <RegwallLayerPreview logoPositionPreview={mainLogoPositionPreview}
                                          buttonPositionPreview={mainButtonPositionPreview}
                                          backgroundColor={mainBackgroundColor}
                                          buttonBackgroundColor={mainButtonBackgroundColor}
                        />
                    )
                }
            }}
            </FormDataConsumer>
        </div>
    );
};

export default MainLayerManager;
