import * as User from './entities/User';
import * as SiteConfig from './entities/SiteConfig';
import * as CustomTemplates from './entities/CustomTemplates';

const resources = [
    User,
    SiteConfig,
    CustomTemplates,
];

export default resources;
