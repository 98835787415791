import React, { useCallback, useState, useRef } from "react";
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  useQueryWithStore,
} from "react-admin";
import useTemplateEngine from "../hooks/useTemplateEngine";
import { useFormState } from "react-final-form";
import OffersInput from "./OffersInput";
import { FieldArray } from "react-final-form-arrays";
import Grid from "./Grid";
import { Field } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PreviewArea from "../components/PreviewArea";
const useStyles = makeStyles((props) => ({
  button_select_custom_template: {
    height: '40px',
    marginTop: '10px'
  },
  custom_template_select: {
    width: '300px'
  },
}));

const OffersForm = ({ input: { name } }) => {
  const classes = useStyles();
  const { values } = useFormState();

  const {
    loaded,
    error: errorQuery,
    data: dataQuery,
  } = useQueryWithStore({
    type: "getOne",
    resource: "custom-templates",
    payload: { id: values?.offer_custom_template },
  });

  const previewTemplate = useTemplateEngine(
    `${dataQuery?.css_template || ``} ${dataQuery?.template || ``}`,
      {offers_items: values?.offers || [], offers_name: values?.offers_name || ''}
  );

  return (
    <>
      <TextInput
        className={classes.medium_width_input}
        label="Offers Name"
        source={`offers_name`}
      />
      <Grid columns={2} width={'600px'}>
        <ReferenceInput
            alwaysOn
            source={`offer_custom_template`}
            label="Custom template"
            reference="custom-templates"
            perPage={100}
            resource={""}
            filter={{ tag: "offer" }}
            className={classes.custom_template_select}
        >
        <AutocompleteInput
          optionText="headline"
          alwaysOn
          resettable
        />
        </ReferenceInput>
        <Button variant="contained" color={'primary'}
                href={`#/custom-templates/${values?.offer_custom_template}`}
                className={classes.button_select_custom_template}
        >
            Edit Custom template
        </Button>
      </Grid>
      <Grid columns={3} width={"100%"}>
        <FieldArray
          name={`offers`}
          component={OffersInput}
        />
      </Grid>
        <Field
          name={"preview_area"}
          height="100%"
          width="100%"
          template={previewTemplate}
          component={PreviewArea}
          injectValue={true}
        />
    </>
  );
};

export default OffersForm;
