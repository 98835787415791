import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import Cookies from 'js-cookie';
import { stringify } from 'query-string';

const currentBranch = window.currentBranch ? window.currentBranch.toLowerCase() : 'default';

const makeBranchedUrl = url => `${url + (url.indexOf('?') >= 0 ? '&' : '?')}branch=${currentBranch}&clean_data_for_front=true&check_token=true&status=InProgress`;


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json', credentials: 'include' });
    }
    const newUrl = makeBranchedUrl(url);
    // add your own headers here
    options.headers.set('X-User-Name', localStorage.getItem('user'));

    if (localStorage.getItem('role')) {
        options.headers.set('X-Role', localStorage.getItem('role'));
    }

    if (localStorage.getItem('expiresIn')) {
        options.headers.set('X-ExpiresIn', localStorage.getItem('expiresIn'));
    }

    if (localStorage.getItem('token')) {
        options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    }

    return fetchUtils.fetchJson(window.currentBranch ? newUrl : url, options)
        .then(response => response)
        .catch(error => {
            if (401 === error.status) {
                window.location.href = '#/login'
            }
            const message = error.body && error.body.detail ? error.body.detail : `${error.body.status} ${error.body.message}`;

            throw new Error(message);
        });
};

const restClient = jsonServerProvider('/api/admin', httpClient );

export default restClient;

export {
    currentBranch,
    makeBranchedUrl,
    httpClient as fetch
};
