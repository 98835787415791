import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {green, grey, red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '1em'
    },
    layer_manager_content: {
        padding: '20px',
        border: '1px solid #DCDCDC'
    },
    layer_manager_row_content: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    row_element_selected: {
        borderWith: '2px',
        borderStyle: 'solid',
        borderColor: green[200],
        backgroundColor: green[200],
        height: '50px',
        marginRight: '5%',
        width: '25%',
        cursor: 'pointer'
    },
    row_element_available: {
        borderWith: '2px',
        borderStyle: 'dashed',
        borderColor: green[200],
        height: '50px',
        marginRight: '5%',
        width: '25%',
        cursor: 'pointer'
    },
    row_element_not_available: {
        borderWith: '2px',
        borderStyle: 'dashed',
        borderColor: red[200],
        height: '50px',
        marginRight: '5%',
        width: '25%',
    },
    title_placeholder: {
        backgroundColor: grey[100],
        height: '100px',
        width: '80%',
        marginTop: '25px'
    },
    text_placeholder: {
        backgroundColor: grey[100],
        height: '25px',
        width: '60%',
        marginTop: '25px',
        marginBottom: '25px',
    },
    element_placeholder: {
        backgroundColor: grey[200],
        height: '50px',
        width: '25%',
        marginBottom: '25px'
    },
});

const LayerManager = (props) => {
    const classes = useStyles();

    return (
        <div>
            <p className={classes.title}>Click to choose {props.element} position</p>
            <div className={classes.layer_manager_content}>
                <div className={classes.layer_manager_row_content}>
                    <div className={
                                    props.elementPosition.verticalUp.rowLeft ?
                                    classes.row_element_selected :
                                    props.elementPosition.verticalUp.rowLeft === null ?
                                    classes.row_element_not_available :
                                    classes.row_element_available
                                    }
                         onClick={props.elementPosition.verticalUp.rowLeft !== null ? () => props.handlePosition(props.element,'verticalUp','rowLeft'): () => {}}
                    >
                    </div>
                    <div className={
                                    props.elementPosition.verticalUp.rowCenter ?
                                    classes.row_element_selected :
                                    props.elementPosition.verticalUp.rowCenter === null ?
                                    classes.row_element_not_available :
                                    classes.row_element_available
                                    }
                         onClick={props.elementPosition.verticalUp.rowCenter !== null ? () => props.handlePosition(props.element,'verticalUp','rowCenter'): () => {}}
                    >
                    </div>
                    <div className={
                                    props.elementPosition.verticalUp.rowRight ?
                                    classes.row_element_selected :
                                    props.elementPosition.verticalUp.rowRight === null ?
                                    classes.row_element_not_available :
                                    classes.row_element_available
                                    }
                         onClick={props.elementPosition.verticalUp.rowRight !== null ? () => props.handlePosition(props.element,'verticalUp','rowRight'): () => {}}
                    >
                    </div>
                </div>
                <div className={classes.title_placeholder}></div>
                <div className={classes.text_placeholder}></div>
                {
                props.element === 'button' && props.layer === 'main' ?
                <div className={classes.layer_manager_row_content}>
                    <div className={
                                    props.elementPosition.verticalCenter.rowLeft ?
                                    classes.row_element_selected :
                                    props.elementPosition.verticalCenter.rowLeft === null ?
                                    classes.row_element_not_available :
                                    classes.row_element_available
                                    }
                         onClick={props.elementPosition.verticalCenter.rowLeft !== null ? () => props.handlePosition(props.element,'verticalCenter','rowLeft'): () => {}}
                    >
                    </div>
                    <div className={
                                    props.elementPosition.verticalCenter.rowCenter ?
                                    classes.row_element_selected :
                                    props.elementPosition.verticalCenter.rowCenter === null ?
                                    classes.row_element_not_available :
                                    classes.row_element_available
                                    }
                         onClick={props.elementPosition.verticalCenter.rowCenter !== null ? () => props.handlePosition(props.element,'verticalCenter','rowCenter'): () => {}}
                    >
                    </div>
                    <div className={
                                    props.elementPosition.verticalCenter.rowRight ?
                                    classes.row_element_selected :
                                    props.elementPosition.verticalCenter.rowRight === null ?
                                    classes.row_element_not_available :
                                    classes.row_element_available
                                }
                         onClick={props.elementPosition.verticalCenter.rowRight !== null ? () => props.handlePosition(props.element,'verticalCenter','rowRight'): () => {}}
                    >
                    </div>
                </div> :
                <div className={classes.element_placeholder}></div>
                }
                {
                    props.layer === 'main' &&
                    <div className={classes.text_placeholder}></div>
                }
                {
                    props.layer === 'main' &&
                    <div className={classes.layer_manager_row_content}>
                        <div className={
                                        props.elementPosition.verticalDown.rowLeft ?
                                        classes.row_element_selected :
                                        props.elementPosition.verticalDown.rowLeft === null ?
                                        classes.row_element_not_available :
                                        classes.row_element_available
                                        }
                             onClick={props.elementPosition.verticalDown.rowLeft !== null ? () => props.handlePosition(props.element,'verticalDown','rowLeft'): () => {}}
                        >
                        </div>
                        <div className={
                                        props.elementPosition.verticalDown.rowCenter ?
                                        classes.row_element_selected :
                                        props.elementPosition.verticalDown.rowCenter === null ?
                                        classes.row_element_not_available :
                                        classes.row_element_available
                                        }
                             onClick={props.elementPosition.verticalDown.rowCenter !== null ? () => props.handlePosition(props.element,'verticalDown','rowCenter'): () => {}}
                        >
                        </div>
                        <div className={
                                        props.elementPosition.verticalDown.rowRight ?
                                        classes.row_element_selected :
                                        props.elementPosition.verticalDown.rowRight === null ?
                                        classes.row_element_not_available :
                                        classes.row_element_available
                                        }
                             onClick={props.elementPosition.verticalDown.rowRight !== null ? () => props.handlePosition(props.element,'verticalDown','rowRight'): () => {}}
                        >
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default LayerManager;
