import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { useFormState } from 'react-final-form';

const RegwallLayerPreview = (props) => {
    const { values } = useFormState();

    const useStyles = makeStyles({
        preview_content: {
            padding: '20px',
            border: '1px solid #E8E8E8',
            marginBottom: '50px',
        },
        kiosq_main_layer: {
            display: 'flex',
            justifyContent: 'center'
        },
        kiosq_main_layer_container: {
            padding: '20px',
            width: '602px',
        },
        kiosq_logo_rowLeft: {
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        kiosq_logo_rowCenter: {
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
            order:'2'
        },
        kiosq_logo_rowRight: {
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
            order:'2'
        },
        kiosq_logo_img: {
            width: '140px',
        },
        kiosq_description: {
            fontSize: '14px',
            lineHeight: '22px',
            marginBottom: '25px',
            '& h1' : {
                marginBottom: '0.5rem',
                fontSize: '28px',
                lineHeight: '34px',
                marginTop: '0px',
            },
            '& h2' : {
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: '24px',
                marginBottom: '10px',
                marginTop: '0px',
            },
            '& h3' : {
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '24px',
                marginBottom: '10px',
                marginTop: '0px',
            }
        },
        kiosq_conditions: {
            fontSize: '14px',
            lineHeight: '22px',
            '& ul': {
                listStyleType: 'disc',
                listStylePosition: 'outside',
                marginBottom: '25px',
                marginLeft: '16px',
                padding:'0px',
                '& li': {
                    fontSize: '14px',
                    lineHeight: '20px',
                }
            },
            '& p' : {
                margin: '0px',
            },
        },
        newsletter_form__wrapper: {
            display: 'flex',
            position:'relative',
            flexDirection:'column',
            alignItems: 'center'
        },
        newsletter_form__main_section: {
            padding: '0px 10px 15px 10px',
            textAlign:'center',
            width: 'auto'
        },
        form__email_input__kiosq: {
            width: '100%',
            lineHeight: '2em',
            padding: '0 10px',
            marginBottom: '4px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            fontSize: '1em',
            color: '#000'
        },
        form__checkbox_label: {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '0px',
            paddingTop: '15px',
            textAlign: 'left',
            lineHeight: '1.5em',
            fontSize: '0.85em',
            color: '#000'
        },
        form__checkbox_input__kiosq: {
            padding: '0px',
            boxSizing: 'border-box',
            color: '#000',
            cursor: 'pointer',
            position:'relative',
            top: '2px',
            flex:'0 0 auto',
            display:'inline',
            width: '13px',
            height: '13px',
            margin:'0px 8px 0px 0px'
        },
        form__submit_input__kiosq: {
            textAlign: 'center',
            lineHeight: '2em',
            color: '#fff',
            border: 'none',
            fontSize: '1em',
            textTransform: 'uppercase',
            backgroundColor: '#000',
            width: '100%'
        },
        newsletter_form__footer: {
            fontSize: '0.8em',
            color: '#666',
            lineHeight: '1.3em',
            textAlign: 'left',
            marginTop: '10px'
        },
        newsletter_form__footer_link: {
            color: '#333',
            textDecoration: 'underline'
        },
    });
    const classes = useStyles();

    return (
            <div className={classes.preview_content}>
                <div className={classes.kiosq_main_layer}>
                    <div className={classes.kiosq_main_layer_container} style={{backgroundColor: props.backgroundColor}}>
                        { values.logo !== undefined && values.logo !== null && props.logoPositionPreview.vertical === 'verticalUp' ?
                          <div className={
                                  props.logoPositionPreview.row === 'rowLeft'?
                                  classes.kiosq_logo_rowLeft :
                                  props.logoPositionPreview.row === 'rowCenter'?
                                  classes.kiosq_logo_rowCenter :
                                  classes.kiosq_logo_rowRight
                              }
                          >
                              <img className={classes.kiosq_logo_img} src={ values.logo } />
                          </div>:
                          null
                        }

                        <div className={classes.kiosq_description}>
                            { (values.textBeforeButton !== undefined && values.textBeforeButton !== null) && parse(values.textBeforeButton) }
                        </div>
                        <div className={classes.newsletter_form__wrapper}>
                            <section className={classes.newsletter_form__main_section}>
                                <div>
                                    <input type="email" className={classes.form__email_input__kiosq}  name="MAIL" required="" placeholder="Your Email Address"/>
                                    <label className={classes.form__checkbox_label}>
                                        <input type="checkbox" className={classes.form__checkbox_input__kiosq} name="CONTACT_OTHER_BRANDS" required=""/>
                                        Contact me with news and offers from other Future brands
                                    </label>
                                    <label className={classes.form__checkbox_label} style={{marginBottom: '15px'}}>
                                        <input type="checkbox" className={classes.form__checkbox_input__kiosq} name="CONTACT_PARTNERS" required=""/>
                                        Receive email from us on behalf of our trusted partners or sponsors
                                    </label>
                                    <input  disabled type="submit" style={{backgroundColor: props.buttonBackgroundColor}} className={classes.form__submit_input__kiosq} value="SIGN UP" required=""/>
                                    <div className={classes.newsletter_form__footer}>By submitting your information you agree to the <span className={classes.newsletter_form__footer_link} >Terms &amp; Conditions</span> and <span className={classes.newsletter_form__footer_link} >Privacy Policy</span> and are aged 16 or over.</div>
                                </div>
                            </section>
                        </div>
                        <div className={classes.kiosq_conditions}>
                            {(values.textAfterButton !== undefined && values.textAfterButton !== null) && parse(values.textAfterButton)}
                        </div>
                        {
                          values.logo !== undefined && values.logo !== null && props.logoPositionPreview.vertical === 'verticalDown' ?
                          <div className={
                                  props.logoPositionPreview.row === 'rowLeft'?
                                  classes.kiosq_logo_rowLeft :
                                  props.logoPositionPreview.row === 'rowCenter'?
                                  classes.kiosq_logo_rowCenter :
                                  classes.kiosq_logo_rowRight
                              }
                          >
                              <img className={classes.kiosq_logo_img} src={ values.logo } />
                          </div> :
                          null
                        }
                    </div>
                </div>
            </div>
    );
};

export default RegwallLayerPreview;
