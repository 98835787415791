import React from 'react';
import { Admin, Resource } from 'react-admin';
import resources from '../resources';
import restClient from '../restClient';
import authProvider from '../authProvider';
import LogoutButton from './LogoutButton';
import { Dashboard } from './Dashboard';

const AdminApp = (props) => {
    const isAuthenticated = props.isAuthenticated;
    const user = props.user;

    const customAuthProvider = authProvider(isAuthenticated);
    return (
        <Admin
                dataProvider={restClient}
                authProvider={customAuthProvider}
                logoutButton={LogoutButton}
                dashboard={Dashboard}
        >
            {() => resources.map(resource => {
                return (['sites-configs', 'users', 'custom-templates'].includes(resource.name)) && user.roles.includes('SuperAdmin') ?
                    <Resource
                        name={resource.name}
                        list={resource.List}
                        edit={resource.Edit}
                        show={resource.Show}
                        icon={resource.Icon}
                        create={resource.Create}
                        options={{ label: resource.label }}
                        {...resource}
                    /> :
                    resource.name === 'users' && user.roles.includes('Admin') ?
                        <Resource
                            name={resource.name}
                            list={resource.List}
                            edit={resource.Edit}
                            show={resource.Show}
                            create={resource.Create}
                            options={{ label: resource.label }}
                            {...resource}
                        /> :
                        null
            })}
        </Admin>
    )
};

export default AdminApp;
