import React from 'react';
import {
  SimpleFormIterator,
  ArrayInput
} from 'react-admin';
import { useFormState } from 'react-final-form';

const ArrayInputContent = (props) =>{
    function isDisableAdd(values){
        if (values !== undefined) {
            return values.length >= 1
        }else {
            return false
        }
    }
    const { values } = useFormState()
    const disableAddSource = isDisableAdd(values[props.arrayInputSource]);
    return (
        <ArrayInput source={props.arrayInputSource}>
            <SimpleFormIterator disableAdd={disableAddSource}>
                {props.children}
            </SimpleFormIterator>
        </ArrayInput>
    )
}

export default ArrayInputContent;
