import React, { useState } from 'react';
import {
  TextInput,
  BooleanInput,
  FormDataConsumer,
  useRecordContext
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {green, grey, blue } from '@material-ui/core/colors';
import LayerManager from './LayerManager';
import FormDataContent from './FormDataContent';
import RegwallAuthLayerPreview from './RegwallAuthLayerPreview';
import { CompactPicker } from 'react-color';
import TextEditor from './TextEditor';

const useStyles = makeStyles({
    section_title: {
        fontSize: '1.2rem',
        marginBottom: '0px'
    },
    section_title_underline: {
        width: '40px',
        height:'6px',
        backgroundColor: blue[400],
        marginBottom: '20px',

    },
    layers_manager_content: {
        display: 'flex',
        marginBottom: '40px',
    },
    layers_manager_zone: {
        width: '33.3%',
        padding: '0px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    color_picker_zone: {
        padding: '0px 20px 20px 20px'
    },
    long_input: {
        width: '65%',
        marginRight: '10%',
    },
    hidden_input: {
        display: 'none'
    },
    text_editor_content: {
        width: '65%'
    },
    color_picker_content: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '1em'
    },
});

const regwallLogoPositionDefault = {
    verticalUp: {
        rowLeft: true,
        rowCenter: false,
        rowRight: false,
    },
    verticalDown: {
        rowLeft: false,
        rowCenter: false,
        rowRight: false,
    }
};

const regwallButtonPositionDefault = {
    verticalUp: {
        rowLeft: null,
        rowCenter: false,
        rowRight: false,
    },
    verticalCenter: {
        rowLeft: true,
        rowCenter: false,
        rowRight: false,
    },
    verticalDown: {
        rowLeft: false,
        rowCenter: false,
        rowRight: false,
    }
}

const regwallBackgroundColorDefault = '#FFFFFF';
const regwallButtonBackgroundColorDefault = '#808080';

const RegwallAuthLayerManager = (props) => {
    const classes = useStyles();
    const record = useRecordContext(props)

    const [regwallLogoPosition, setRegwallLogoPosition] = useState(
        record && record.regwall_logo_position ?
        record.regwall_logo_position :
        regwallLogoPositionDefault
    );

    const [regwallButtonPosition, setRegwallButtonPosition] = useState(
        record && record.regwall_button_position ?
        record.regwall_button_position :
        regwallButtonPositionDefault
    );

    const [regwallBackgroundColor, setRegwallBackgroundColor] = useState(
        record && record.regwall_background_color ?
        record.regwall_background_color :
        regwallBackgroundColorDefault
    );

    const [regwallButtonBackgroundColor, setRegwallButtonBackgroundColor] = useState(
        record && record.regwall_button_background_color ?
        record.regwall_button_background_color :
        regwallButtonBackgroundColorDefault
    );


    const [regwallLogoPositionPreview, setRegwallLogoPositionPreview] = useState(positionForPreview(regwallLogoPosition));
    const [regwallButtonPositionPreview, setRegwallButtonPositionPreview] = useState(positionForPreview(regwallButtonPosition));


    const [textBeforeButtonRegwall, setTextBeforeButtonRegwall] = useState(
        record && record.textBeforeButtonRegwall ?
        record.textBeforeButtonRegwall :
        ''
    );

    const [textAfterButtonRegwall, setTextAfterButtonRegwall] = useState(
        record && record.textAfterButtonRegwall ?
        record.textAfterButtonRegwall :
        ''
    );

    const [regwallManagementExpanded, setRegwallManagementExpanded] = useState(
        record && record.template_regwall ?
        true : false
    );

    function positionForPreview(elementPosition){
        for (const [vKey, vValue] of Object.entries(elementPosition)) {
            for (const [rKey, rValue] of Object.entries(vValue)) {
                if (rValue) {
                    return ({
                        vertical: vKey,
                        row: rKey
                    })
                }
            }
        }
    };


    function handleChangeRegwallBackgroundColor(color){
        setRegwallBackgroundColor(color.hex);
    };

    function handleChangeRegwallButtonBackgroundColor(color){
        setRegwallButtonBackgroundColor(color.hex);
    };



    function handleRegwallPosition(element, verticalPosition, rowPosition){

        let newLogoPosition = {...regwallLogoPosition};
        let newButtonPosition = {...regwallButtonPosition};


        if (element === 'logo') {
            for (const [vKey, vValue] of Object.entries(newLogoPosition)) {
                for (const [rKey, rValue] of Object.entries(vValue)) {
                    if (vKey === verticalPosition) {
                        if (rKey === rowPosition) {
                            newLogoPosition[vKey][rowPosition] = true;
                            newButtonPosition[vKey][rowPosition] = null;
                        }else if (newLogoPosition[vKey][rKey] && rKey !== rowPosition){
                            newLogoPosition[vKey][rKey] = false;
                            if (newButtonPosition[vKey][rKey] === null) {
                                newButtonPosition[vKey][rKey] = false;
                            }
                        }
                    }else {
                        if (newLogoPosition[vKey][rKey]) {
                            newLogoPosition[vKey][rKey] = false;
                        }
                        if (newButtonPosition[vKey][rKey] === null) {
                            newButtonPosition[vKey][rKey] = false;
                        }
                    }

                }
            }
        }
        if (element === 'button') {
            for (const [vKey, vValue] of Object.entries(newButtonPosition)) {
                for (const [rKey, rValue] of Object.entries(vValue)) {
                    if (vKey === verticalPosition) {
                        if (rKey === rowPosition) {
                            newButtonPosition[vKey][rowPosition] = true;
                            if (vKey in newLogoPosition) {
                                newLogoPosition[vKey][rowPosition] = null;
                            }
                        }else if (newButtonPosition[vKey][rKey] && rKey !== rowPosition){
                            newButtonPosition[vKey][rKey] = false;
                            if (vKey in newLogoPosition) {
                                if (newLogoPosition[vKey][rKey] === null) {
                                    newLogoPosition[vKey][rKey] = false;
                                }
                            }
                        }
                    }else {
                        if (newButtonPosition[vKey][rKey]) {
                            newButtonPosition[vKey][rKey] = false;
                        }
                        if (vKey in newLogoPosition) {
                            if (newLogoPosition[vKey][rKey] === null) {
                                newLogoPosition[vKey][rKey] = false;
                            }
                        }
                    }

                }
            }
        }

        setRegwallLogoPosition(newLogoPosition);
        setRegwallLogoPositionPreview(positionForPreview(newLogoPosition));

        setRegwallButtonPosition(newButtonPosition);
        setRegwallButtonPositionPreview(positionForPreview(newButtonPosition));
    };

    return (
        <div   id="layer_regwall_management">

            <FormDataContent dataContentTitle=" Regwall Layer content">
                <TextInput helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/jtld3qrazv1613402249.svg" className={classes.long_input} source="logo_regwall" />
                <div className={classes.text_editor_content}>
                    <TextEditor editorTitle='Text before button regwall' textContent= {textBeforeButtonRegwall} handleText= {setTextBeforeButtonRegwall}/>
                </div>
                <TextInput helperText="ex: Access now" className={classes.long_input} source="button_text_regwall" />
                <div className={classes.text_editor_content}>
                    <TextEditor editorTitle='Text after button regwall'textContent= {textAfterButtonRegwall} handleText= {setTextAfterButtonRegwall}/>
                </div>
            </FormDataContent>

            <p className={classes.section_title}> Regwall Layer customization</p>
            <div className={classes.section_title_underline}></div>
            <div className={classes.layers_manager_content}>
                <div className={classes.layers_manager_zone}>
                    <LayerManager layer='main' element='logo' elementPosition= {regwallLogoPosition} handlePosition={handleRegwallPosition} />
                </div>
                <div className={classes.layers_manager_zone}>
                    <LayerManager layer='main' element='button' elementPosition= {regwallButtonPosition} handlePosition={handleRegwallPosition} />
                </div>
                <div className={classes.layers_manager_zone}>
                    <div className={classes.color_picker_zone}>
                        <p className={classes.title}>Choose background color</p>
                        <div className={classes.color_picker_content}>
                            <CompactPicker color={ regwallBackgroundColor } onChangeComplete={ handleChangeRegwallBackgroundColor } />
                        </div>
                    </div>
                    <div className={classes.color_picker_zone}>
                        <p className={classes.title}>Choose button background color</p>
                        <div className={classes.color_picker_content}>
                            <CompactPicker color={ regwallButtonBackgroundColor } onChangeComplete={ handleChangeRegwallButtonBackgroundColor } />
                        </div>
                    </div>
                </div>
            </div>
            <FormDataConsumer>
                {({ formData, ...rest }) =>{
                    formData.regwall_logo_position = regwallLogoPosition;
                    formData.regwall_button_position = regwallButtonPosition;
                    formData.regwall_background_color = regwallBackgroundColor;
                    formData.regwall_button_background_color = regwallButtonBackgroundColor;
                    formData.textBeforeButtonRegwall = textBeforeButtonRegwall;
                    formData.textAfterButtonRegwall = textAfterButtonRegwall;
                    return (
                        <div>
                            <TextInput className={classes.hidden_input} source="regwall_logo_position" />
                            <TextInput className={classes.hidden_input} source="regwall_button_position" />
                            <TextInput className={classes.hidden_input} source="regwall_background_color" />
                            <TextInput className={classes.hidden_input} source="regwall_button_background_color" />
                            <TextInput className={classes.hidden_input} source="textBeforeButtonRegwall" />
                            <TextInput className={classes.hidden_input} source="textAfterButtonRegwall" />
                        </div>

                    )}
                }
            </FormDataConsumer>

            <p className={classes.section_title}> Regwall Layer preview </p>
            <div className={classes.section_title_underline}></div>
            <RegwallAuthLayerPreview logoPositionPreview={regwallLogoPositionPreview}
                              buttonPositionPreview={regwallButtonPositionPreview}
                              backgroundColor={regwallBackgroundColor}
                              buttonBackgroundColor={regwallButtonBackgroundColor}
            />
        </div>
    );
};


export default RegwallAuthLayerManager;
