import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
    section_title: {
        fontSize: '1.2rem',
        marginBottom: '0px'
    },
    section_title_underline: {
        width: '40px',
        height:'6px',
        backgroundColor: blue[400],
        marginBottom: '20px'

    },
    info_content: {
        padding: '20px',
        border: '1px solid #E8E8E8',
        marginBottom:'50px'
    }
});

const FormDataContent = (props)=>{
    const classes = useStyles();
        return (
            <div className={classes.section_content}>
                <p className={classes.section_title}>{props.dataContentTitle}</p>
                <div className={classes.section_title_underline}></div>
                <div className={classes.info_content}>
                    {props.children}
                </div>
            </div>
        );
};

export default FormDataContent;
