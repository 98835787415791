import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import MinusIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '../IconButton';
import Grid from '../../components/Grid';
const styles = (theme) => ({
    root: {
        width: '100%',
        marginBottom: 8,
        marginTop: 16,
    },
    detailsRoot: {
        display: 'block',
    },
    expansionPanelSummary: {
        border: "1px #fff solid",
    },
    expansionPanelSummaryTitle:{
        color:"#333",
        fontSize:"16px",
    },
    icon: {
        verticalAlign: 'middle',
        marginRight: 5,
        marginTop: "-3px",
    },
    missing: {
        fontStyle: 'italic',
        color: '#f39c12',
        fontSize: 12,
    },
    quarterWidth: { width: '25%' },
    halfWidth: { width: '50%' },
    fullWidth: { width: '100%' },
    wrapperInputWidthSpan2Link: { gridColumn: 'span 2' },
    wrapperInputWidthSpan3Link: { gridColumn: 'span 3' },
    wrapperInputWidthSpan4Link: { gridColumn: 'span 4' },
    wrapperInputWidthSpan5Link: { gridColumn: 'span 5' },
    wrapperInputWidthSpan6Link: { gridColumn: 'span 6' },
});

const LinkListCard = ({children,
    index,
    fields,
    inputFields,
    source,
    classes,
    initiallyExpanded,
    removeLabel,
    getTitle,
    showEmptyKeys,
    expanded,
    gridColumnTitle = 1,
    gridColumnDetails = 1,
    gridTemplateColumns,
    extendedTitle,
    resource,
    displayIndex,
    hideRemoveButton,
    labelPrefix,
    type}) => {
        const [isExpanded, setIsExpanded] = useState(expanded || false);
        const handleExpand = useCallback(
          () => {
            setIsExpanded(currentExpanded => !currentExpanded);
          },
          [],
        )
        const item = fields.value[index];

        return (
            
                <div style={{ marginBottom: "8px", marginTop: "16px" }} className={classes.root}>
                    <Accordion
                        defaultExpanded={initiallyExpanded(item)}
                        expanded={expanded ? expanded : isExpanded}
                    >
                        <AccordionSummary onClick={handleExpand} expandIcon={expanded !== true  && <ExpandMoreIcon />} className={classes.expansionPanelSummary} style={{backgroundColor: type === 'Section' ? '#dbdbdb' : '#ecdddd' }}>
                            <Grid columns={gridColumnTitle} gridTemplateColumns={gridTemplateColumns} >
                                <Typography className={classes.expansionPanelSummaryTitle}>
                                    <b>{labelPrefix}</b> : {getTitle(item)}
                                </Typography>
                                {extendedTitle && extendedTitle(item) ? <Typography variant="caption">{extendedTitle(item)}</Typography> : ''}
                                {displayIndex && <Typography>{index}</Typography>}
                                {/* {this.showEmptyKeyOn(item, showEmptyKeys)} */}
                            </Grid>
                        </AccordionSummary>
    
                        <AccordionDetails classes={{ root: classes.detailsRoot }} style={{backgroundColor: type === 'Section' ? '#f8f8f8' : 'white' }}>
                            {resource === "abtesting" && extendedTitle && extendedTitle(item) ? <Typography>{extendedTitle(item)}</Typography> : ''}
                            <Grid columns={gridColumnDetails}>
                                {inputFields.map((InputField, i) => (
                                    <div className={InputField.props.wrapperinputfieldclasses} key={`${source}.${InputField.props.source}.${i}`}>
                                        {renderInput(InputField, source, i)}
                                    </div>
                                ))}
                            </Grid>
                            {children
                                && children.length > 0
                                && children.map(component => (renderInput(component, source)))
                            }
                        </AccordionDetails>
                        {hideRemoveButton === false && <AccordionActions>
                            <IconButton
                                color="secondary"
                                icon={iconClasses => <MinusIcon className={iconClasses.leftIcon} />}
                                onClick={() => fields.remove(index)}
                            >
                                {`${removeLabel} #${index + 1}`}
                            </IconButton>
                        </AccordionActions>
    
                        }
    
                    </Accordion>
                </div>
            );
}

/* class LinkListCard extends Component {
    constructor(props) {
        super(props)
        const { initiallyExpanded, expanded, fields, index } = this.props;
        const item = fields.value[index];
        this.state = { isExpanded: initiallyExpanded(item) || initiallyExpanded(index) || expanded || false }
        this.showEmptyKeyOn = this.showEmptyKeyOn.bind(this);
        this.handleExpand = this.handleExpand.bind(this);
    }

    showEmptyKeyOn = (item, myKeys = []) => {
        const { classes } = this.props;
        let emptyKeys = myKeys.filter(key => {
            return !item[key] ? key : null;
        });
        return emptyKeys.length > 0 ?
            <Typography className={classes.missing}>
                <ErrorIcon className={classes.icon} />{emptyKeys.join(' , ').toUpperCase()}
            </Typography>
            : null
    }

    handleExpand() {
        this.setState(prevState => ({ isExpanded: !prevState.isExpanded }))
    }

    render() {
        const {
            children,
            index,
            fields,
            inputFields,
            source,
            classes,
            initiallyExpanded,
            removeLabel,
            getTitle,
            showEmptyKeys,
            expanded,
            gridColumnTitle = 1,
            gridColumnDetails = 1,
            gridTemplateColumns,
            extendedTitle,
            resource,
            displayIndex,
            hideRemoveButton,
            labelPrefix,
            type
        } = this.props;
        const { isExpanded } = this.state;
        const item = fields.value[index];

        return (
            <div className={classes.root}>
                <Accordion
                    defaultExpanded={initiallyExpanded(item)}
                    expanded={expanded ? expanded : isExpanded}
                >
                    <AccordionSummary onClick={this.handleExpand} expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelSummary} style={{backgroundColor: type === 'Section' ? '#dbdbdb' : '#ecdddd' }}>
                        <Grid columns={gridColumnTitle} gridTemplateColumns={gridTemplateColumns} >
                            <Typography className={classes.expansionPanelSummaryTitle}>
                                <b>{labelPrefix}</b> : {getTitle(item)}
                            </Typography>
                            {extendedTitle && extendedTitle(item) ? <Typography variant="caption">{extendedTitle(item)}</Typography> : ''}
                            {displayIndex && <Typography>{index}</Typography>}
                            {this.showEmptyKeyOn(item, showEmptyKeys)}
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails classes={{ root: classes.detailsRoot }} style={{backgroundColor: type === 'Section' ? '#f8f8f8' : 'white' }}>
                        {resource === "abtesting" && extendedTitle && extendedTitle(item) ? <Typography>{extendedTitle(item)}</Typography> : ''}
                        <Grid columns={gridColumnDetails}>
                            {inputFields.map((InputField, i) => (
                                <div className={InputField.props.wrapperinputfieldclasses} key={`${source}.${InputField.props.source}.${i}`}>
                                    {renderInput(InputField, source, i)}
                                </div>
                            ))}
                        </Grid>
                        {children
                            && children.length > 0
                            && children.map(component => (renderInput(component, source)))
                        }
                    </AccordionDetails>
                    {hideRemoveButton === false && <AccordionActions>
                        <IconButton
                            color="secondary"
                            icon={iconClasses => <MinusIcon className={iconClasses.leftIcon} />}
                            onClick={() => fields.remove(index)}
                        >
                            {`${removeLabel} #${index + 1}`}
                        </IconButton>
                    </AccordionActions>

                    }

                </Accordion>
            </div>
        );
    }
} */

function renderInput(InputField, source, index) {

    return React.cloneElement(InputField, {
        key: source,
        source: `${source}${InputField.props.source ? `.${InputField.props.source}` : ``}`,
        id: `${source}.${index}${InputField.props.id ? `.${InputField.props.id}` : ``}`,
        name: `${source}${InputField.props.name ? `.${InputField.props.name}` : ``}`,
        displayindex: `${source}${InputField.props.displayIndex ? `.${InputField.props.displayIndex}` : ``}`,
        // onChangeInput: `${source}${InputField.props.onChangeInput ? `.${InputField.props.onChangeInput}` : ``}`,
        ...(InputField.props.choices && { choices: InputField.props.choices })
    });
}

LinkListCard.defaultProps = {
    initiallyExpanded: record => !record.title && !record.url,
    hideRemoveButton: false,
    displayIndex: false,
};

export default withStyles(styles)(LinkListCard);
