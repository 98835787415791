import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormDataConsumer,BooleanInput,SelectInput,}  from 'react-admin';

const useStyles = makeStyles({
    traffic_exposure_content: {
        display: 'flex',
    },
    traffic_exposure_rate: {
        marginLeft: '10%'
    },
});


const TrafficExposureForm = (props) =>{
    const classes = useStyles();
    const exposureRateChoice = [
        { id: 0, name: '0%' },
        { id: 5, name: '5%' },
        { id: 10, name: '10%' },
        { id: 15, name: '15%' },
        { id: 20, name: '20%' },
        { id: 25, name: '25%' },
        { id: 30, name: '30%' },
        { id: 35, name: '35%' },
        { id: 40, name: '40%' },
        { id: 45, name: '45%' },
        { id: 50, name: '50%' },
        { id: 55, name: '55%' },
        { id: 60, name: '60%' },
        { id: 65, name: '65%' },
        { id: 70, name: '70%' },
        { id: 75, name: '75%' },
        { id: 80, name: '80%' },
        { id: 85, name: '85%' },
        { id: 90, name: '90%' },
        { id: 95, name: '95%' },
        { id: 100, name: '100%' },
    ];
    return (
        <div className={classes.traffic_exposure_content}>
            <BooleanInput helperText="To activate traffic exposure"  label="Traffic Exposure" source="traffic_exposure" />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>{
                        if (formData.traffic_exposure === false) {
                            formData.traffic_exposure_rate = null;
                        }
                        return (
                            <SelectInput helperText="To manage the traffic rate"
                                         disabled={!formData.traffic_exposure}
                                         source="traffic_exposure_rate"
                                         label="Rate"
                                         choices={exposureRateChoice}
                                         allowEmpty= {formData.traffic_exposure ? false : true}
                                         emptyValue={null}
                                         className={classes.traffic_exposure_rate}
                            />
                        )}
                    }
                </FormDataConsumer>

        </div>
    )
}

export default TrafficExposureForm;
