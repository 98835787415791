const authProvider =(isAuthenticated) => ({
    // authentication
    checkAuth: () => {
        if (isAuthenticated) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
    getPermissions: params => Promise.resolve(),
    logout: () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        return Promise.resolve();
    },
})

export default authProvider;
